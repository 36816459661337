import * as types from "@/store/mutation-types";
import SrvCommon from "@/services/common.js";
import SrvFeedback from "@/services/feedback.js";
import SrvFeedbackDelivery from "@/services/delivery-feedback.js";
import SrvDeliveryNetwork from "@/services/delivery-network.js";
import SrvLocal from "@/services/local.js";
import SrvBooking from "@/services/booking.js";

import SrvDelivery from "@/services/delivery.js";
import SrvSatisfaction from "@/services/satisfaction.js";
import SrvRankingSatisfaction from "@/services/ranking-satisfaction.js";
import SrvGlobal from "@/services/global.js";
import SrvNetwork from "@/services/network.js";
import SrvIndicators from "@/services/satisfaction.js";
import SrvAttention from "@/services/attention.js";
import brandMixin from "@/mixins/brand.js";

export default {
  mixins: [brandMixin],
  data: () => ({
    colours: ["#222226", "#85D9F3", "#0EB5E9", "#2D92B2", "#2A72A1", "#B51C32", "#7BECB5", "#057092", "#979797", "#47C54D", "#E6C500", "#DC2CE9", "#F70026", "#32CBBA", "#53CC75", "#2626CE", "#625ECC", "#B043CC", "#D70993", "#D81248"],
    imgCommons: {
      help: require("@/assets/img/ico-help.svg"),
      power: require("@/assets/img/power.png"),
      download: require("@/assets/img/ico-download.svg"),
      close: require("@/assets/img/ico-close.svg"),
      warning: require("@/assets/img/modal-warning.svg"),
      modalDownload: require("@/assets/img/modal-download.svg"),
      down: require("@/assets/img/ico-down.svg"),
      order: require("@/assets/img/icon_order.png"),
      search: require("@/assets/img/ico-search.png"),
      arrows: require("@/assets/img/arrow-average.png"),
      detractoresFace: require("@/assets/img/detractoresCara.svg"),
    },
    labelsQuestionPoll: {
      rating_arrived_well: "Llegó bien",
      rating_delivery_time: "Tiempo de entrega",
      rating_environment: "Ambiente",
      rating_expected_amount: "Cantidad esperada",
      rating_hygiene: "Higiene",
      rating_kitchen: "Cocina",
      rating_price: "Precio",
      rating_service: "Servicio",
    },
    palette: {negative: {class: "cRed", color: "#e95d4b"}, neutral: {class: "cOrange", color: "#f5a623"}, positive: {class: "cGreen", color: "#89c445"}, without: {class: "cGray", color: "#cccccc"}},
  }),

  methods: {
    getWithColCategory(withAvailable, categories) {
      return "width:" + (withAvailable - Object.keys(categories).length * 2) / Object.keys(categories).length + "%";
    },
    getColorPercent(valor) {
      if (valor <= 0.5) {
        return "cNegativeDark";
      } else if (valor < 25.0) {
        return "cNeutralDark";
      } else {
        return "cPositiveDark";
      }
    },
    checkLimitTextarea: function (field, limit) {
      this.data[field] = this.data[field].substr(0, limit);
    },
    getColorByNoteNormalized({note, base, type = "class"}) {
      let fNote = parseFloat(note);
      fNote = this.$options.filters.formatNumber(fNote, 1, 1);
      fNote = fNote.replace(",", ".");
      fNote = parseFloat(fNote);
      const palette = {negative: {class: "cRed", classBG: "bgRed", color: "#e95d4b"}, neutral: {class: "cOrange", classBG: "bgOrange", color: "#f5a623"}, positive: {class: "cGreen", classBG: "bgGreen", color: "#89c445"}, without: {class: "cGray", classBG: "bgGray", color: "#cccccc"}};
      let keyColor = palette.without;
      base = this.isAdverbia ? 5 : 10;
      if (base === 5) {
        if (fNote <= 3) {
          keyColor = palette.negative;
        } else if (fNote < 4.5) {
          keyColor = palette.neutral;
        } else if (fNote >= 4.5 && fNote <= 5) {
          keyColor = palette.positive;
        } else {
          keyColor = palette.without;
        }
      }
      if (base === 10) {
        if (fNote <= 5) {
          keyColor = palette.negative;
        } else if (fNote <= 7.5) {
          keyColor = palette.neutral;
        } else if (fNote > 7.5) {
          keyColor = palette.positive;
        } else {
          keyColor = palette.without;
        }
      }
      return keyColor[type];
    },
    getNoteNormalized(note) {
      //si es adverbia y no tiene un solo sitio note / 2
      //si es adverbia y tiene varios sitios y no estan filtrando por uno solo note
      let noteNormalized = note;
      /*if (this.isAdverbia && !this.useSiteRating) {
        let totalSitesOptionInFilter = 0
        if (this.$store.getters.getSitesOpinions && this.$store.getters.getSitesOpinions.internet ) {
          totalSitesOptionInFilter = this.$store.getters.getSitesOpinions.internet.sites.length
        }
        if (this.$store.getters.getSitesOpinions && this.$store.getters.getSitesOpinions.delivery ) {
          totalSitesOptionInFilter += this.$store.getters.getSitesOpinions.delivery.sites.length
        }
        if (this.$store.getters.getSitesSelected.length === 0 && totalSitesOptionInFilter > 1) {
          noteNormalized = noteNormalized / 2
        }

      }*/
      if (this.isAdverbia) {
        noteNormalized = noteNormalized / 2;
      }
      return noteNormalized;
    },
    getColorMiniChartPie: function (data, min = true) {
      let color = "";
      if (data < 41) {
        color = "#F93549";
      } else if (data >= 41 && data < 71) {
        color = "#FCAE19";
      } else {
        color = "#89C445";
      }
      let styles = "background: conic-gradient(" + color + " " + data + "%, #fff 0);border-color:" + color + ";";
      if (!min) {
        styles = "background-image: conic-gradient(" + color + " " + data + "%, lightgrey " + data + "%);";
      }
      return styles;
    },
    getColorByPercent: function (data) {
      let color = "";
      if (!this.localMarketingExtended) return "#000000";
      if (data < 41) {
        color = "#F93549";
      } else if (data >= 41 && data < 71) {
        color = "#FCAE19";
      } else {
        color = "#89C445";
      }

      return color;
    },
    getColor(number) {
      if (number <= 4) {
        return "bg-minimun";
      } else if (number > 4 && number < 6) {
        return "bg-medium";
      } else {
        return "bg-large";
      }
    },
    getImageSiteLocalMark(site) {
      return require(`@/assets/img/local/${site}.svg`);
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    logout() {
      if (window.localStorage.getItem("_token")) {
        window.localStorage.removeItem("_token");
        window.localStorage.removeItem("_user");
        window.localStorage.removeItem("_user_info");
        window.sessionStorage.removeItem("_user_info");
      } else if (window.sessionStorage.getItem("_token")) {
        window.sessionStorage.removeItem("_token");
        window.sessionStorage.removeItem("_user");
        window.localStorage.removeItem("_user_info");
        window.sessionStorage.removeItem("_user_info");
      }
      window.location = "/";
    },
    getColorNote(note, type = "class") {
      let fNote = parseFloat(note);
      fNote = this.$options.filters.formatNumber(fNote, 1, 1);
      fNote = fNote.replace(",", ".");
      fNote = parseFloat(fNote);
      const palette = {negative: {class: "cRed", color: "#e95d4b"}, neutral: {class: "cOrange", color: "#f5a623"}, positive: {class: "cGreen", color: "#89c445"}, without: {class: "cGray", color: "#cccccc"}};
      let keyColor = palette.without;
      if (fNote <= 5) {
        keyColor = palette.negative;
      } else if (fNote <= 7.5) {
        keyColor = palette.neutral;
      } else if (fNote > 7.5) {
        keyColor = palette.positive;
      } else {
        keyColor = palette.without;
      }

      if (type === "class") {
        return keyColor.class;
      }
      return keyColor.color;
    },
    getColorNoteGPS(note, type = "class") {
      let fNote = parseFloat(note);
      fNote = this.$options.filters.formatNumber(fNote, 1, 1);
      fNote = fNote.replace(",", ".");
      fNote = parseFloat(fNote);
      const palette = {negative: {class: "cRed", color: "#e95d4b"}, neutral: {class: "cOrange", color: "#f5a623"}, positive: {class: "cGreen", color: "#89c445"}, without: {class: "cGray", color: "#cccccc"}};
      let keyColor = palette.without;
      if (fNote < 7) {
        keyColor = palette.negative;
      } else if (fNote >= 7 && fNote < 9) {
        keyColor = palette.neutral;
      } else if (fNote >= 9) {
        keyColor = palette.positive;
      } else {
        keyColor = palette.without;
      }

      if (type === "class") {
        return keyColor.class;
      }
      return keyColor.color;
    },
    getColorNoteCategoryGPS(note, type = "class") {
      let fNote = parseFloat(note);
      fNote = this.$options.filters.formatNumber(fNote, 1, 1);
      fNote = fNote.replace(",", ".");
      fNote = parseFloat(fNote);
      const palette = {negative: {class: "cRed", color: "#e95d4b"}, neutral: {class: "cOrange", color: "#f5a623"}, positive: {class: "cGreen", color: "#89c445"}, without: {class: "cGray", color: "#cccccc"}};
      let keyColor = palette.without;
      if (fNote <= 3) {
        keyColor = palette.negative;
      } else if (fNote > 3 && fNote <= 4) {
        keyColor = palette.neutral;
      } else if (fNote > 4) {
        keyColor = palette.positive;
      } else {
        keyColor = palette.without;
      }

      if (type === "class") {
        return keyColor.class;
      }
      return keyColor.color;
    },
    getArrow(data, data_tend) {
      const formatedData = Number.isInteger(data) ? data : data.toFixed(1)
      const formatedTendData = Number.isInteger(data_tend) ? data_tend : data_tend.toFixed(1)
      if (formatedData > formatedTendData) {
        return "triangle-up";
      } else if (formatedData === formatedTendData) {
        return "square-equal";
      } else if (formatedData < formatedTendData) {
        return "triangle-down";
      }
      return "";
    },
    getColorForTend(data, data_tend) {
      const formatedData = Number.isInteger(data) ? data : data.toFixed(1)
      const formatedTendData = Number.isInteger(data_tend) ? data_tend : data_tend.toFixed(1)
      if (formatedData > formatedTendData) {
        return "#3CC480";
      } else if (formatedData < formatedTendData) {
        return "#E8523F";
      } else {
        return "#FCAE19";
      }
    },
    loadUrlParams() {
      this.$store.commit(types.QUERY_PARAMS, this.$route.query);
      // Save url params filters

      if (this.$route.query.type) {
        this.$store.commit(types.COMMON_TYPES_SELECTED, this.$route.query.type);

        this.OptionTypeSelect = this.$route.query.type;
      }
      if (this.$route.query.site) {
        this.$store.commit(types.COMMON_SITES_SELECTED, this.$route.query.site.split(";").map(Number));
      }
      if (this.$route.params.paramCOD) {
        let partes = this.$route.params.paramCOD.split("-");
        this.$store.commit(types.SET_NODE, partes[partes.length - 2]);
        let slug = "";
        for (let index in partes) {
          if (slug !== "") {
            slug += "-";
          }
          slug += partes[index];
        }
        if (partes[partes.length - 1] === "C") {
          this.$store.commit(types.SET_NODE_LEVEL, 9);
        } else {
          this.$nextTick(() => {
            let elementoTree = document.querySelector('[data-id="' + partes[partes.length - 2] + '"][data-type="N"]');
            this.$store.commit(types.SET_NODE_LEVEL, elementoTree.getAttribute("data-level"));
          });
        }

        this.$store.commit(types.BREAD_CRUMB, slug);
      }
      if (this.$route.query.typeRestaurant) {
        this.$store.commit(types.COMMON_TYPES_RESTAURANT_SELECTED, this.$route.query.typeRestaurant.split(";").map(Number));
      }
      if (this.$route.query.center_models) {
        this.$store.commit(types.COMMON_CENTER_MODELS_SELECTED, this.$route.query.center_models.split(";").map(Number));
      }
      if (this.$route.query.after) {
        this.$store.commit(types.COMMON_DATE_AFTER, this.$route.query.after);
      }
      if (this.$route.query.before) {
        this.$store.commit(types.COMMON_DATE_BEFORE, this.$route.query.before);
      }
      if (this.$route.query.region) {
        this.$store.commit(types.COMMON_REGION_SELECTED, this.$route.query.region.split(";").map(String));
      }
      if (this.$route.query.no_tree) {
        this.$store.commit(types.TREE_SHOW_TREE, false);
      }
    },
    cleanUrlParams() {
      this.$store.commit(types.QUERY_PARAMS, "");
      this.$store.commit(types.COMMON_TYPES_SELECTED, undefined);
      this.$store.commit(types.COMMON_SITES_SELECTED, []);
      this.$store.commit(types.SET_NODE, "");
      this.$store.commit(types.COMMON_TYPES_RESTAURANT_SELECTED, "");
      this.$store.commit(types.COMMON_REGION_SELECTED, "");
      this.$store.commit(types.TREE_SHOW_TREE, true);
      this.$store.commit(types.COMMON_AGGREGATOR_ID, null);
      this.$store.commit(types.COMMON_MOMENT_ID, null);
      this.$store.commit(types.COMMON_DISTANCE_ID, null);
    },
    updateUrl() {
      let query = {};

      if (this.$store.getters.getDateAfter) {
        query.after = this.$store.getters.getDateAfter;
      }
      if (this.$store.getters.getDateBefore) {
        query.before = this.$store.getters.getDateBefore;
      }
      if (this.$store.getters.getTypesRestaurantSelected) {
        const types = this.$store.getters.getTypesRestaurantSelected;
        if (types.length) {
          query.typeRestaurant = types.join(";");
        } else {
          delete query.typeRestaurant;
        }
      }
      if (this.$store.getters.getCenterModelsSelected) {
        const models = this.$store.getters.getCenterModelsSelected;
        if (models.length) {
          query.center_models = models.join(";");
        } else {
          delete query.center_models;
        }
      }
      if (this.$store.getters.getTypesSelected) {
        query.type = this.$store.getters.getTypesSelected;
      }
      if (this.$store.getters.getSitesSelected) {
        const sites = this.$store.getters.getSitesSelected;
        if (sites.length) {
          query.site = sites.join(";");
        } else {
          delete query.site;
        }
      }
      if (this.$store.getters.getRegionSelected) {
        const regions = this.$store.getters.getRegionSelected;
        if (regions.length) {
          query.region = regions.join(";");
        } else {
          delete query.region;
        }
      }
      if (this.$store.getters.getSitesDeliverySelected) {
        const sites = this.$store.getters.getSitesDeliverySelected;
        if (sites.length) {
          query.sitesdelivery = sites.join(";");
        } else {
          delete query.sitesdelivery;
        }
      }
      if (this.$store.getters.getTreeSelected) {
        query.tree = this.$store.getters.getTreeSelected.id;
      }
      if (!this.$store.getters.getShowTree) {
        query.no_tree = false;
      }
      // SEARCH IN FEEDBACK REVIEWS
      if (this.$store.getters.getOpinionsSearch) {
        query.search = this.$store.getters.getOpinionsSearch;
      }
      if (this.$store.getters.getLocalGoogleSearch) {
        query.search = this.$store.getters.getLocalGoogleSearch;
      }
      // ID AGREGADOR PANTALLA AGGREGADORES POR DISTANCIA TIEMPO
      if (this.$store.getters.getAggregatorId) {
        query.aggregator = this.$store.getters.getAggregatorId;
      }
      // ID MOMENTO PANTALLA AGGREGADORES POR DISTANCIA TIEMPO
      if (this.$store.getters.getMomentId) {
        query.moment = this.$store.getters.getMomentId;
      }
      // ID MOMENTO PANTALLA AGGREGADORES POR DISTANCIA TIEMPO
      if (this.$store.getters.getDistanceId) {
        query.distance = this.$store.getters.getDistanceId;
      }
      if (this.$route.query.zone) {
        query.zone = this.$route.query.zone;
      }
      this.$store.commit(types.QUERY_PARAMS, query);
      this.$router.replace({name: this.$router.history.current.name, params: {paramCOD: this.$route.params.paramCOD}, query: query}).catch(() => {
      });
    },
    goEditRestaurant(id) {
      let elementoTree = document.querySelector('[data-id="' + id + '"][data-type="C"]');
      if (elementoTree) {
        //location.href = '/local/restaurant/' + elementoTree.getAttribute('data-slug') + '-C'
        this.$router.push({name: "LocalRestaurant", params: {paramCOD: elementoTree.getAttribute("data-slug") + "-C"}, query: this.$route.query});
      }
    },
    getUrlEditRestaurant(id) {
      let elementoTree = document.querySelector('[data-id="' + id + '"][data-type="C"]');
      if (elementoTree) {
        //location.href = '/local/restaurant/' + elementoTree.getAttribute('data-slug') + '-C'
        return {name: "LocalRestaurant", params: {paramCOD: elementoTree.getAttribute("data-slug") + "-C"}, query: this.$route.query};
      }
      return null;
    },
    goItemNextLevel(item, center = false) {
      // !!!!IMPORTANT FUNCIÓN TEMPORAL DE ABRIL CENTRO O NODO DESDE LISTADOS
      let type = "N";

      if ((item.is_center && item.is_center == true) || center) {
        type = "C";
      }
      let name;
      if (Object.keys(item).includes("name")) {
        name = item.name;
      } else if (Object.keys(item).includes("brand")) {
        name = item.brand;
      }
      let selector = item.id + "-" + name.replaceAll(" ", "") + "-" + type;

      let elementTree = document.getElementById(selector);

      //Find children id when don't exists html node
      if (!elementTree) {
        const tree = this.$store.getters.getTreeData;
        let newItem;

        const name = item.name ?? item.brand;

        tree.forEach((_item) => {
          newItem = _item.children.filter((el) => el.name === name);
        });

        selector = newItem?.[0]?.id + "-" + name.replaceAll(" ", "") + "-" + type;
        elementTree = document.getElementById(selector);
      }

      let slug = elementTree.getAttribute("data-slug") + "-" + type;

      let rutaCompletaActual = this.$route.fullPath;
      let rutaActual = this.$route.path;
      if (rutaActual.charAt(rutaActual.length - 1) === "/") {
        rutaActual = rutaActual.substr(0, rutaActual.length - 1);
      }
      let partesRuta = rutaCompletaActual.split("?");
      let filtros = "";
      if (partesRuta.length > 1) {
        filtros = partesRuta[1];
      }
      let rutaLimpia = "";
      let partesRutaSinFiltros = rutaActual.split("/");
      if (partesRutaSinFiltros.length > 0) {
        for (let part in partesRutaSinFiltros) {
          if (part < partesRutaSinFiltros.length - 1) {
            if (rutaLimpia != "") {
              rutaLimpia += "/";
            }
            rutaLimpia += partesRutaSinFiltros[part];
          }
        }
      }

      let rutaFinal = "/" + rutaLimpia + "/" + slug;

      // SI ES CENTRO ENVIAMOS A PAGINA GENERAL DE LA SECCION
      if (type === "C") {
        if (rutaActual.indexOf("network/presence/ranking") > -1) {
          rutaFinal = "/network/presence/global/" + slug;
        } else if (rutaActual.indexOf("/network/valoration/ranking") > -1) {
          rutaFinal = "/network/valoration/ranking/" + slug;
        } else if (rutaActual.indexOf("satisfaction/quiz/rankings") > -1) {
          rutaFinal = "/satisfaction/quiz/indicators/" + slug;
        }
      }

      if (filtros != "") {
        rutaFinal += "?" + filtros;
      }
      if (item.returnUrl) {
        return rutaFinal;
      } else {
        this.$router.push(rutaFinal);
      }
    },
    async setTree(item) {
      this.$store.commit(types.TREE_SELECT, item);
      const parts = this.$route.params.paramCOD?.split("-");
      const res = await SrvCommon.getTree();
      if (res.status === 200) {
        if (this.isAdverbia) {
          location.href = "/network/resume/" + res.data[0].id + `-${parts.length ? parts[1] : "N"}?tree=` + item.id;
        } else {
          location.href = "/global/home/" + res.data[0].id + `-${parts.length ? parts[1] : "N"}?tree=` + item.id;
        }
      }
    },
    goFeedback(type = "internet") {
      let query = this.$route.query;
      let pathName = "FeedBackHome";
      if (type.toLowerCase() === "delivery") {
        this.$store.commit(types.COMMON_TYPES_SELECTED, "delivery");
        pathName = "DeliveryReviewsReviews";
        query.type = "delivery";
      } else {
        this.$store.commit(types.COMMON_TYPES_SELECTED, "internet");
        query.type = "internet";
      }
      this.$router.push({name: pathName, params: {paramCOD: this.$route.params.paramCOD}, query: query});
    },
    goWoe() {
      this.$router.push({name: "SatisfactionSac", params: {paramCOD: this.$route.params.paramCOD}});
    },
    goResume() {
      this.$router.push({name: "NetworkHome", params: {paramCOD: this.$route.params.paramCOD}});
    },
    goNetworkGps() {
      this.$router.push({name: "NetworkGps", params: {paramCOD: this.$route.params.paramCOD}});
    },
    goNetworkCompetence(type = "internet") {
      let query = this.$route.query;
      let pathName = "NetworkCompetence";
      if (type === "delivery") {
        this.$store.commit(types.COMMON_TYPES_SELECTED, "delivery");
        query.type = "delivery";
        pathName = "DeliveryCompetenceGlobal";
      } else {
        this.$store.commit(types.COMMON_TYPES_SELECTED, "internet");
        query.type = "internet";
      }
      this.$router.push({name: pathName, params: {paramCOD: this.$route.params.paramCOD}, query: query});
    },
    goNetworkValoration() {
      this.$router.push({name: "NetworkValorationPositioning", params: {paramCOD: this.$route.params.paramCOD}});
    },
    async downloadExcel(params = null, numbers = 0) {
      if (numbers > 100000) {
        this.$store.commit(types.LIMIT_EXCEDED_EXCEL, true);
        return false;
      }
      this.$store.commit(types.DOWNLOADING_EXCEL, true);
      let nameView = this.$route.name;
      if (nameView === "HealthyPokeCommentsHome") {
        const res = await SrvRankingSatisfaction.getSatisfactionComments(params, true);
        if (res.status === 200) {
          this.generateExcel(res);
        }
      }
      if (nameView === "DeliveryResumeHome") {
        const res = await SrvDelivery.getGlobalDelivery(params, true);
        if (res.status === 200) {
          this.generateExcel(res);
        }
      }
      if (nameView === "DeliveryRankingRestaurantsHome") {
        const res = await SrvDeliveryNetwork.getRankings(params.ordering, params.searchQuery, true);
        if (res.status === 200) {
          this.generateExcel(res);
        }
      }

      if (nameView === "DeliveryPosicionamientoHome") {
        const res = await SrvDelivery.getAggregators(params, true);
        if (res.status === 200) {
          this.generateExcel(res);
        }
      } else if (nameView === "DeliveryReviewsKeywords") {
        const res = await SrvFeedbackDelivery.getKeywords(true);
        if (res.status === 200) {
          this.generateExcel(res);
        }
      } else if (nameView === "DeliveryReviewsReviews") {
        SrvFeedbackDelivery.getReviews(params.ordering, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            } else {
              // empty
            }
          },
          () => {
          }
        );
      } else if (nameView === "DeliveryReviewsDish") {
        SrvFeedbackDelivery.getDishKeywords(params.ordering, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "FeedBackOpinions") {
        SrvFeedback.getReviews(params.ordering, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            } else {
              // empty
            }
          },
          () => {
          }
        );
      } else if (nameView === "FeedBackEmployees") {
        SrvFeedback.getEmployees(params.ordering, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "FeedBackDish") {
        SrvFeedback.getDishKeywords(params.ordering, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "FeedBackDishRanking") {
        SrvFeedback.getDishRanking(params.search, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "FeedBackKeywords") {
        SrvFeedback.getKeywords(params.type, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "SatisfactionQuizComments") {
        SrvSatisfaction.getSatisfactionComments(params, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "SatisfactionSacClaims" || nameView === "AttentionInfoIncidents") {
        SrvSatisfaction.getSatisfactionSacClaims("claims", true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "SatisfactionSacSuggestions") {
        SrvSatisfaction.getSatisfactionSacClaims("suggestions", true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "GlobalSummary") {
        SrvGlobal.getGlobal(false, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "NetworkValorationRanking") {
        SrvNetwork.getRankings(true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "NetworkValorationPositioning") {
        SrvNetwork.getRankings(true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );

        //NetworkValorationPositioning

        //NetworkValorationPositioning
      } else if (nameView === "NetworkValorationPositioningLast") {
        SrvNetwork.getPositioningLast(true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "NetworkValorationPositioningLast12Weeks") {
        SrvNetwork.getPositioningLast12Weeks(true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "NetworkCompetenceGlobal") {
        SrvNetwork.getCompetenceGlobal(true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "IndividualNetworkCompetenceGlobal") {
        SrvNetwork.getIndividualCompetence(true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "SatisfactionQuizIndicators") {
        SrvIndicators.getSatisfactionIndicators(true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "HealthyPokeRankingHome") {
        SrvRankingSatisfaction.getSatisfactionRanking(params.ordering, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "SatisfactionQuizRankings") {
        SrvSatisfaction.getSatisfactionRanking(params.ordering, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "SatisfactionSacEvolution" || nameView === "AttentionInfoEvolution") {
        SrvSatisfaction.getSatisfactionSacEvolution(true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "NetworkResume") {
        SrvNetwork.getGlobalInternet(true, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "AttentionAppsReviews") {
        SrvAttention.getReviewsApp(null, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "NetworkAggregators") {
        SrvNetwork.getAggregators(params, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "LocalAnalyticsGoogle") {
        SrvLocal.downloadXlsFile(params, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res);
            }
          },
          () => {
          }
        );
      } else if (nameView === "FeedBackBooking") {
        SrvBooking.downloadXlsFile(params, true).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res, "Booking_");
            }
          },
          () => {
          }
        );
      } else if (nameView === "GoogleTrend") {
        SrvNetwork.downloadGoogleTrendXlsFile(params).then(
          (res) => {
            if (res.status === 200) {
              this.generateExcel(res, "True_rating_");
            }
          },
          () => {
          }
        );
      }
    },
    generateExcel(res, pre_name) {
      var blob = new Blob([res.data], {
        type: res.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let elementName = pre_name ? pre_name : "";
      if (!this.$store.getters.getShowTree) {
        let center = document.querySelector('[data-id="' + this.$store.getters.getNode + '"][data-type="C"]');
        elementName += center.getAttribute("data-name");
      } else {
        let elementActive = document.querySelector(".tree-active");
        elementName += elementActive.getAttribute("data-name");
      }
      link.download = `${elementName}_${this.$store.getters.getDateAfter}_${this.$store.getters.getDateBefore}.xlsx`;
      this.$store.commit(types.DOWNLOADING_EXCEL, false);
      link.click();
    },
    /**
     * Find element in nested array of object by 'id'
     * @param arr
     * @param {*} itemId
     * @param {*} nestingKey
     * @param idKey
     * @returns object
     */
    findItemNested(arr = [], itemId, nestingKey = "children", idKey = "id") {
      return arr.reduce((a, item) => {
        if (a) return a;
        if (item[idKey] === itemId) return item;
        if (item[nestingKey]) return this.findItemNested(item[nestingKey], itemId, nestingKey, idKey);
      }, null);
    },
    onCheckCenterModels(items) {
      if (items.length) {
        this.$store.commit(
          types.COMMON_CENTER_MODELS_SELECTED,
          items.map((item) => item.value)
        );
      } else {
        this.$store.commit(types.COMMON_CENTER_MODELS_SELECTED, []);
      }

      this.updateUrl();
    },
    onCheckRegion(items) {
      if (items.length) {
        this.$store.commit(
          types.COMMON_REGION_SELECTED,
          items.map((item) => item.name)
        );
      } else {
        this.$store.commit(types.COMMON_REGION_SELECTED, []);
      }
      this.updateUrl();
    },
    onCheckSitesDelivery(items) {
      if (items.length) {
        this.$store.commit(
          types.COMMON_SITES_DELIVERY_SELECTED,
          items.map((item) => item.value)
        );
      } else {
        this.$store.commit(types.COMMON_SITES_DELIVERY_SELECTED, []);
      }
      this.updateUrl();
    },
    onCheckOpinionsSites(val, valChild = []) {
      this.$store.commit(types.COMMON_TYPES_SELECTED_INTERNET_AUTOMATIC, false);
      this.$store.commit(types.COMMON_TYPES_SELECTED, val);
      this.$store.commit(types.COMMON_SITES_SELECTED, valChild);
      this.updateUrl();
    },
    onCheckRestaurantTypes(items) {
      this.$store.commit(
        types.COMMON_TYPES_RESTAURANT_SELECTED,
        items.map((item) => Number(item.value))
      );
      this.updateUrl();
    },
    getTextBlackIfGroup(item) {
      if (item.rating || item.delivery_rating || item.bottom_centers || item.top_centers) {
        return "textBlackGroup";
      }
    },
    getCurrentNode({slug}) {
      const slugParts = slug.split("-");
      const currentTree = this.$store.getters.getTreeData;

      let item = null
      for (let i = 0; i < slugParts.length; i++) {
        const _id = slugParts[i]

        if (!item) {
          item = currentTree.filter(item => item.id.toString() === _id)
          continue
        }

        const selection = item?.[0]?.children?.length ? item?.[0].children : item?.[0]?.centers
        item = selection.filter(item => item.id.toString() === _id)
      }
      return item
    },
    getAllCenterFromNode(node) {
      const centers = []
      if (!node) return
      const traverse = (item) => {
        if (item.centers) {
          centers.push(...item.centers);
        }
        if (item.children) {
          item.children.forEach(traverse);
        }
      };

      [node].forEach(traverse);
      return centers;
    }
  },
  computed: {
    months() {
      return [this.$gettext("January"), this.$gettext("February"), this.$gettext("March"), this.$gettext("April"), this.$gettext("May"), this.$gettext("June"), this.$gettext("July"), this.$gettext("August"), this.$gettext("September"), this.$gettext("October"), this.$gettext("November"), this.$gettext("December")];
    },
    isInstanceHotels() {
      if (["andilana", "grupomambo", "orbityhotelmanagement"].includes(this.nameInstance)) {
        return true;
      }
      return false;
    },
    isDemoFacebook() {
      if ((this.nameInstance === "dashboard" || this.nameInstance === "localhost") && this.$store.getters.getNode == 188 && this.typeNode === "center") {
        return true;
      }
      return false;
    },
    useSiteRating() {
      if (this.isAdverbia && this.$store.getters.getSitesSelected.length === 1) {
        return true;
      }
      return false;
    },
    itemNotHasDelivery() {
      let querySelector =
        'span[data-slug="' +
        this.paramCOD
          .split("-")
          .slice(0, this.paramCOD.split("-").length - 1)
          .join("-") +
        '"]';
      if (document.querySelector(querySelector) && !document.querySelector(querySelector).getAttribute("data-has-delivery")) {
        return true;
      }
      return false;
    },
    itemNotHasInternet() {
      let querySelector =
        'span[data-slug="' +
        this.paramCOD
          .split("-")
          .slice(0, this.paramCOD.split("-").length - 1)
          .join("-") +
        '"]';
      if (document.querySelector(querySelector) && !document.querySelector(querySelector).getAttribute("data-has-internet")) {
        return true;
      }
      return false;
    },
    hasEmployees() {
      return this.$store.getters.getHasEmployees;
    },
    filtersDate() {
      return [this.$store.getters.getDateAfter, this.$store.getters.getDateBefore].join();
    },
    filterForSites() {
      let restSelected = " ";
      for (var i = 0; i < this.$store.getters.getTypesRestaurantSelected.length; i++) {
        restSelected += " " + this.$store.getters.getTypesRestaurantSelected[i].text;
      }
      return [this.$store.getters.getDateAfter, this.$store.getters.getDateBefore, this.$store.getters.getNode, restSelected].join();
    },
    nameInstance() {
      return window.location.hostname.split(".")[0];
    },
    enableDistanceFilter() {
      return !["grupomambo"].includes(this.nameInstance);
    },
    opinionSitesOnlyInternet() {
      return this.opinionSites;
    },
    isStaff() {
      if (this.userName.indexOf("gastroranking.es") > -1 || this.userName.indexOf("theysay.me") > -1 || this.userName.indexOf("adverbia.es") > -1) return true;
      return false;
    },
    showCoverManager() {
      if (this.opinionSites && this.opinionSites.length > 0) {
        if (this.typeOpinionSelected !== "delivery" && this.opinionSites.internet.sites.findIndex((e) => e.code === "CMN") > -1) {
          return true;
        }
      }
      return false;
    },
    localMarketingExtended() {
      return this.$store.getters.getUserInfo?.can_edit_manual_sitelocalmark;
    },
    queryParams() {
      return this.$store.getters.queryParams;
    },
    opinionSites() {
      return this.$store.getters.getSitesOpinions;
    },
    opinionAllSitesDelivery() {
      if (this.$store.getters.getAllSitesOpinions?.delivery?.sites) {
        return this.$store.getters.getAllSitesOpinions.delivery.sites;
      }
      return [];
    },
    opinionSitesDelivery() {
      if (this.$store.getters.getSitesOpinions.delivery) {
        return this.$store.getters.getSitesOpinions.delivery.sites;
      } else {
        return [];
      }
    },
    reasonsSelected() {
      return this.$store.getters.getReasonsSelected;
    },
    siteSelected() {
      return this.$store.getters.getSitesSelected;
    },
    regions() {
      return this.$store.getters.getRegions;
    },
    showDelivery() {
      return this.hasDelivery && (this.typeOpinionSelected === "delivery" || this.typeOpinionSelected === "" || this.typeOpinionSelected === null || this.typeOpinionSelected === "undefined");
    },
    hasDelivery() {
      return this.$store.getters.getHasDelivery;
    },
    hasCompetence() {
      return this.$store.getters.getHasCompetence;
    },
    hasSurvey() {
      return this.$store.getters.getUserInfo.config.has_survey;
    },
    typeOpinionSelected() {
      return this.$store.getters.getTypesSelected;
    },
    showDownloadInHome() {
      let nameView = this.$route.name;
      if (["DeliveryCompetenceGlobal", "DeliveryGPSHome", "DeliveryRankingRestaurantsHome", "DeliveryReviewsKeywords", "DeliveryReviewsReviews", "FeedBackEmployees", "AttentionAppsReviews", "AttentionInfoIncidents", "FeedBackKeywords", "FeedBackOpinions", "SatisfactionQuizComments", "SatisfactionQuizRankings", "SatisfactionSacClaims", "SatisfactionSacSuggestions", "NetworkValorationPositioningLast", "NetworkValorationPositioningLast12Weeks", "NetworkValorationRanking", "NetworkGps"].includes(nameView)) {
        return false;
      }
      return true; // poner a true cuando tengamos disponible la funcionalidad
    },
    currentTree() {
      return this.$store.getters.getTreeSelected;
    },
    optionsTree() {
      return this.$store.getters.getUserInfo.trees;
    },
    restaurantTypes() {
      return this.$store.getters.getTypesRestaurant;
    },
    typeRestaurantSelected() {
      const selected = [...this.$store.getters.getTypesRestaurantSelected];
      if (selected.length) {
        return selected;
      } else {
        return [];
      }
    },
    regionSelect() {
      let selected = [];
      if (this.$store.getters.getRegionSelected.length > 0) {
        this.$store.getters.getRegions.forEach((r) => {
          if (this.$store.getters.getRegionSelected.includes(r.text)) {
            selected.push(r.value);
          }
        });
      }
      if (selected.length) {
        return selected;
      } else {
        return [];
      }
    },
    allSitesDeliverySelect() {
      if (!this.$store.getters.getAllSitesOpinions?.delivery?.sites) {
        return [];
      }
      let selected = [];
      if (this.$store.getters.getSitesDeliverySelected.length > 0) {
        this.$store.getters.getAllSitesOpinions.delivery.sites.forEach((r) => {
          if (this.$store.getters.getSitesDeliverySelected.includes(r.id)) {
            selected.push(r.id);
          }
        });
      }
      if (selected.length) {
        return selected;
      } else {
        return [];
      }
    },
    sitesDeliverySelect() {
      let selected = [];
      if (this.$store.getters.getSitesDeliverySelected.length > 0) {
        this.$store.getters.getSitesOpinions.delivery.sites.forEach((r) => {
          if (this.$store.getters.getSitesDeliverySelected.includes(r.id)) {
            selected.push(r.id);
          }
        });
      }
      if (selected.length) {
        return selected;
      } else {
        return [];
      }
    },
    centerModels() {
      return this.$store.getters.getCenterModels;
    },
    centerModelsSelected() {
      const selected = [...this.$store.getters.getCenterModelsSelected];
      if (selected.length) {
        return selected;
      } else {
        return [];
      }
    },
    subNavClosed: function () {
      return !this.$store.getters.getOpen;
    },
    nodeLevel() {
      if (this.$store.getters.getNodeLevel && this.$store.getters.getNodeLevel > 0) {
        return true;
      }
      return false;
    },
    level() {
      return {
        company: this.$store.getters.getNodeLevel == 0,
        brand: this.$store.getters.getNodeLevel == 1,
        zone: this.$store.getters.getNodeLevel == 2,
        subzone: this.$store.getters.getNodeLevel == 3,
        local: this.$store.getters.getNodeLevel == 4,
      };
    },
    typeNode() {
      if (this.$store.getters.getNodeLevel == 9) {
        return "center";
      } else if (this.$store.getters.getNodeLevel == 1 || this.$store.getters.getNodeLevel == 2 || this.$store.getters.getNodeLevel == 3) {
        return "brand";
      } else {
        return "group";
      }
    },
    userName() {
      return this.$store.getters.userName;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    },
    filterApply() {
      let restSelected = " ";
      for (var i = 0; i < this.$store.getters.getTypesRestaurantSelected.length; i++) {
        restSelected += " " + this.$store.getters.getTypesRestaurantSelected[i].text;
      }
      let sitesSelected = " ";
      for (var e = 0; e < this.$store.getters.getSitesSelected.length; e++) {
        sitesSelected += " " + this.$store.getters.getSitesSelected[e];
      }
      return [this.$store.getters.getDateAfter, this.$store.getters.getDateBefore, this.$store.getters.getNodeLevel, this.$store.getters.getNode, restSelected, this.$store.getters.getTypesSelected, sitesSelected, this.$store.getters.getRegionSelected].join();
    },
    is_alsea() {
      return this.$store.getters.getUserInfo?.config?.is_alsea;
    },
    hasReports() {
      return this.$store.getters.getUserInfo?.has_reports;
    },
    hasReportsExcel() {
      return this.$store.getters.getUserInfo?.has_excel_reports;
    },
    hasDeliveryAnalytics() {
      return this.$store.getters.getUserInfo?.config?.has_delivery_analytics;
    },
  },
};
