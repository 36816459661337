// Stores
import Stores from "@/store/index";
// importamos los tipos de mutación de vuex
import * as types from "@/store/mutation-types";
// Config
import appConfig from "@/config-app.js";

// Axios
import axios from "axios";

axios.defaults.baseURL = appConfig.api;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export default {
  getToken() {
    if (window.localStorage.getItem("_token")) {
      return window.localStorage.getItem("_token");
    } else if (window.sessionStorage.getItem("_token")) {
      return window.sessionStorage.getItem("_token");
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get("token")) {
      window.localStorage.setItem("_token", urlParams.get("token"));
      return urlParams.get("token");
    }
  },
  getConfigHeadersExcel() {
    return {headers: {Accept: "application/xlsx", "Content-Type": "application/xlsx"}, responseType: "blob"};
  },
  getBasicParamsQuery(query) {
    if (Stores.getters.getDateAfter) {
      let after = Stores.getters.getDateAfter;
      if (after.indexOf("T")) {
        let partes = after.split("T");
        after = partes[0];
      }
      query += `?after=${after}`;
    }
    if (Stores.getters.getDateBefore) {
      let before = Stores.getters.getDateBefore;
      if (before.indexOf("T")) {
        let partes = before.split("T");
        before = partes[0];
      }
      query += `&before=${before}`;
    }

    // SI EL NIVEL NO EXISTE ES CENTRO
    if (Stores.getters.getNodeLevel === 9) {
      if (Stores.getters.getNode) {
        query += `&center=${Stores.getters.getNode}`;
      }
    } else {
      if (Stores.getters.getNode) {
        query += `&node=${Stores.getters.getNode}`;
      }
    }

    // SI VIENE EL TIPO DE CENTRO
    if (Stores.getters.getTypesRestaurantSelected.length > 0) {
      Stores.getters.getTypesRestaurantSelected.forEach((type) => {
        query += `&center_type=${type}`;
      });
    }
    // REGION
    if (Stores.getters.getRegionSelected.length > 0) {
      Stores.getters.getRegionSelected.forEach((reg) => {
        query += `&region=${reg}`;
      });
    }

    query += `&tree=${Stores.getters.getTreeSelected.id}`;

    return query;
  },
  getTree(id = null) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let token = this.getToken();
      let query = `/tree/`;

      if (id) {
        query += `?tree=${id}`;
      } else {
        query += `?tree=${Stores.getters.getTreeSelected.id}`;
      }
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error;
          if (status === 401) {
            this.logout();
          }
          reject(error);
        });
    });
  },
  getAccount(tree) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      // Stores.commit(types.PENDING)
      let token = this.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;

      axios
        .get(`/user_info/`)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          window.localStorage.setItem("_user_info", JSON.stringify(res.data));

          let arbolUrl = res.data.trees.filter((t) => {
            if ((tree && t.id == tree) || (res.data.default_node && t.id == res.data.default_node)) {
              return t;
            }
          });

          if (arbolUrl.length > 0) {
            Stores.commit(types.TREE_SELECT, arbolUrl[0]);
          } else {
            Stores.commit(types.TREE_SELECT, res.data.trees[0]);
          }

          if (!res.data.trees) {
            resolve({data: {trees: undefined}});
          } else {
            // Siempre que se obtenga el user_info se puede actualizar el USER_NAME
            res.data.email && Stores.commit("USER_NAME", res.data.email);
            Stores.commit(types.USER_INFO, res.data);
            //Stores.commit(types.TREE_SELECT,res.data.trees[0])

            resolve(res);
          }
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          //const status = error
          // if (status === 401) {
          this.logout();
          // }
          reject(error);
        });
    });
  },
  getCenterModels() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/center_models/`;

      if (Stores.getters.getDateAfter) {
        let after = Stores.getters.getDateAfter;
        if (after.indexOf("T")) {
          let partes = after.split("T");
          after = partes[0];
        }
        query += `?after=${after}`;
      }
      if (Stores.getters.getDateBefore) {
        let before = Stores.getters.getDateBefore;
        if (before.indexOf("T")) {
          let partes = before.split("T");
          before = partes[0];
        }
        query += `&before=${before}`;
      }

      // TODO: Revisar el filtro tree para center_models con Lucas/David.
      /**
       * Center
       * SI EL NIVEL NO EXISTE ES CENTRO
       */
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`;
        }
      } else {
        /**
         * node
         */
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`;
        }
      }

      /**
       * review_type
       */
      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`;
      }

      /**
       * site
       */
      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach((sit) => {
          query += `&site=${sit}`;
        });
      }

      // TODO: Revisar el filtro tree para center_models con Lucas/David.
      /**
       * tree
       */
      if (Stores.getters.getTreeSelected.id) {
        query += `&tree=${Stores.getters.getTreeSelected.id}`;
      }

      /**
       * region
       */
      // REGION
      if (Stores.getters.getRegionSelected.length > 0) {
        Stores.getters.getRegionSelected.forEach((reg) => {
          query += `&region=${reg}`;
        });
      }

      /**
       * center_type
       */
      if (Stores.getters.getTypesRestaurantSelected) {
        Stores.getters.getTypesRestaurantSelected.forEach((type) => {
          query += `&center_type=${type}`;
        });
      }
      query += `&limit=100`;
      let token = this.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);

          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          if (error === 401) {
            this.logout();
          }
          reject(error);
        });
    });
  },
  getTypesRestaurant() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/center_types/`;

      if (Stores.getters.getDateAfter) {
        let after = Stores.getters.getDateAfter;
        if (after.indexOf("T")) {
          let partes = after.split("T");
          after = partes[0];
        }
        query += `?after=${after}`;
      }
      if (Stores.getters.getDateBefore) {
        let before = Stores.getters.getDateBefore;
        if (before.indexOf("T")) {
          let partes = before.split("T");
          before = partes[0];
        }
        query += `&before=${before}`;
      }

      // SI EL NIVEL NO EXISTE ES CENTRO
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`;
        }
      }

      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`;
      }

      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach((sit) => {
          query += `&site=${sit}`;
        });
      }

      if (Stores.getters.getTreeSelected.id) {
        query += `&tree=${Stores.getters.getTreeSelected.id}`;
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach((type) => {
          query += `&center_model=${type}`;
        });
      }

      /**
       * region
       */
      // REGION
      if (Stores.getters.getRegionSelected.length > 0) {
        Stores.getters.getRegionSelected.forEach((reg) => {
          query += `&region=${reg}`;
        });
      }

      let token = this.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);

          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error;
          if (status === 401) {
            this.logout();
          }
          reject(error);
        });
    });
  },
  getCenters() {
    //http://localhost:8000/backend/api/v1/basic_user_centers/?node=16&tree=1
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/basic_user_centers/`;

      query = this.getBasicParamsQuery(query);

      let token = this.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);

          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error;
          if (status === 401) {
            this.logout();
          }
          reject(error);
        });
    });
  },
  getAllSitesOpinions() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/all_delivery_sites/`;

      query = this.getBasicParamsQuery(query);

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach((type) => {
          query += `&center_model=${type}`;
        });
      }

      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getDistanceMap && Stores.getters.getDistanceMapActive) {
          query += `&distance=${Stores.getters.getDistanceMap}`;
        }
      }

      let token = this.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);

          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error;
          if (status === 401) {
            this.logout();
          }
          reject(error);
        });
    });
  },
  getSitesOpinions() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/sites/`;

      query = this.getBasicParamsQuery(query);
      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`;
      }
      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach((sit) => {
          query += `&site=${sit}`;
        });
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach((type) => {
          query += `&center_model=${type}`;
        });
      }

      let token = this.getToken();
      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);

          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error;
          if (status === 401) {
            this.logout();
          }
          reject(error);
        });
    });
  },
  getReasons() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/complaint_reasons/`;
      query = this.getBasicParamsQuery(query);

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach((type) => {
          query += `&center_model=${type}`;
        });
      }

      // llamada a la API
      let token = this.getToken();

      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            this.logout();
          }
          reject(error);
        });
    });
  },
  getCategories() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/categories/`;
      query = this.getBasicParamsQuery(query);

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach((type) => {
          query += `&center_model=${type}`;
        });
      }

      // llamada a la API
      let token = this.getToken();

      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            this.logout();
          }
          reject(error);
        });
    });
  },
  getRegions() {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);
      let query = `/regions/`;

      if (Stores.getters.getDateAfter) {
        let after = Stores.getters.getDateAfter;
        if (after.indexOf("T")) {
          let partes = after.split("T");
          after = partes[0];
        }
        query += `?after=${after}`;
      }
      if (Stores.getters.getDateBefore) {
        let before = Stores.getters.getDateBefore;
        if (before.indexOf("T")) {
          let partes = before.split("T");
          before = partes[0];
        }
        query += `&before=${before}`;
      }

      // SI EL NIVEL NO EXISTE ES CENTRO
      if (Stores.getters.getNodeLevel === 9) {
        if (Stores.getters.getNode) {
          query += `&center=${Stores.getters.getNode}`;
        }
      } else {
        if (Stores.getters.getNode) {
          query += `&node=${Stores.getters.getNode}`;
        }
      }

      // SI VIENE EL TIPO DE CENTRO
      if (Stores.getters.getTypesRestaurantSelected.length > 0) {
        Stores.getters.getTypesRestaurantSelected.forEach((type) => {
          query += `&center_type=${type}`;
        });
      }

      if (Stores.getters.getTypesSelected) {
        query += `&review_type=${Stores.getters.getTypesSelected}`;
      }

      if (Stores.getters.getSitesSelected.length > 0) {
        Stores.getters.getSitesSelected.forEach((sit) => {
          query += `&site=${sit}`;
        });
      }

      if (Stores.getters.getTreeSelected.id) {
        query += `&tree=${Stores.getters.getTreeSelected.id}`;
      }

      /**
       * center_model
       */
      if (Stores.getters.getCenterModelsSelected) {
        Stores.getters.getCenterModelsSelected.forEach((type) => {
          query += `&center_model=${type}`;
        });
      }

      // llamada a la API
      let token = this.getToken();

      // llamada a la API
      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(query)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            this.logout();
          }
          reject(error);
        });
    });
  },
  restorePassword(email) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);

      // llamada a la API
      axios
        .post(`/password_reset/`, email)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          reject(error);
        });
    });
  },
  restorePasswordSet(password, token) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);

      // llamada a la API
      axios
        .post(`/password_reset/confirm/`, {password, token})
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          reject(error);
        });
    });
  },
  restorePasswordTokenCheck(token) {
    return new Promise((resolve, reject) => {
      // mostramos Loading
      Stores.commit(types.PENDING);

      // llamada a la API
      axios
        .post(`/password_reset/validate_token/`, token)
        .then((res) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          // Ocultamos Loading
          Stores.commit(types.NOT_PENDING);
          const status = error;
          if (status === 401) {
            this.logout();
          }
          if (status === 404) {
            reject("404");
          }
          reject(error);
        });
    });
  },
  getNext(url) {
    return new Promise((resolve, reject) => {
      let token = this.getToken();

      axios.defaults.headers.common["Authorization"] = "Token " + token;
      axios
        .get(url)
        .then((res) => {
          Stores.commit(types.NOT_PENDING);
          resolve(res);
        })
        .catch((error) => {
          Stores.commit(types.NOT_PENDING);
          const status = error.response.status;
          if (status === 401) {
            this.logout();
          }
          reject(error);
        });
    });
  },
  logout() {
    Stores.dispatch("logout");
    window.location = "/";
    return false;
  },
};
