<template lang="pug">
  div(v-if="typeNode === 'group'")
    ranking-level-group(:siteLocalMark="siteLocalMark" :dataProfile="dataProfile" :loading="loading")
  div(v-else)
    v-row.px-2.ma-0(:class="{'opacity60': !showRanking}")
      // LOGO MARCA
      div.margin-bottom-0.pb-0.col-2
        skeleton-card(v-if="loading")
        v-card.gridFull.ma-0.py-1.not-shadow.d-flex.align-center.justify-center(v-if="!loading")
          .d-flex.justify-center
            img.logoBrand(v-if="dataProfile.node_level_1 && dataProfile.node_level_1.logo" :src="dataProfile.node_level_1.logo" height="61")
      div.margin-bottom-0.pb-0.col-10
        skeleton-card(v-if="loading")
        v-card.gridFull.ma-0.py-1.not-shadow.align-center.d-flex.boxProfileComplete(v-if="!loading")
          .d-flex.pl-4.align-center
            div.chartPercentCircle(:style="getColorMiniChartPie(dataProfile.node_level_1.percent_profile_completed, false)")
              p {{ dataProfile.node_level_1.percent_profile_completed | formatNumber(0,0) }}%
            span.pl-3.profileComplete.text-12 #[translate Profile complete]
      <breadcrumbs class="breads d-flex align-center" :borderBottom="false" />

    .gridFull(v-if="loading")
      v-skeleton-loader(type="table")
    div(v-else)
      v-row#headRankingGoogle.headTitleGrid.text-13
        v-col.titleGrid(md="6")
          button.inline-flex.items-center(:class="{ 'active': showRanking }" @click="selectGoogle = 0")
            translate Ranking
          button.inline-flex.items-center(v-if="$config.featureConfig['googlePendingRestaurants']" :class="{ 'active': !showRanking }")
            div.gr-loader-spinner-inline(v-if="isLoadingGoogleUserLocations")
              span.gr-loader-spinner
              span Loading...
            select.cursor-pointer(v-else-if="hasTokenGoogle"
              v-model="selectGoogle"
              :disabled="!hasTokenGoogle"
            )
              option(v-for="(option, id) in options" :value="id") {{ option.name }}
            span(v-else)
      v-row.mx-6.mb-0.mt-0
        v-col.pt-1
          p.text-12(v-if="!isGenericEntity") {{ $gettext('Edición de Restaurantes en Bloque. Selecciona los restaurantes y la plataforma en los que desea publicar.') }}
          p.text-12(v-else) {{ $gettext('Edición de Negocios en Bloque. Selecciona los negocios y la plataforma en los que desea publicar.') }}
          div.d-flex.boxActionMultipleBySite.align-center.pa-3( :class="{'opacity-50': idsBussinesChecked.length == 0, 'premiumDisabled': !hasPremium}")
            <img :src="img.googleMaps" />
            <span class="text-13 mx-3">Google</span>
            <select class="selectCustom" @change="openModalMultiple($event)" v-model="actionOpenModal"  :disabled="idsBussinesChecked.length == 0">
            <option default value="default">Seleccionar acción</option>
            <option v-for="(action, index) in optionsMultipleGoogle" :value="action.key">{{ action.name }}</option>
            </select>

    div(v-if="!loading")
      v-row#filtersRanking.align-center
        v-col.justify-start(cols="4")
          input.filter-text(type="text" v-model="filters.nameItem" :placeholder="labelSearch")
        v-col.text-left.justify-start.d-flex.flex-1.align-center.gap-2em(cols="8")
          div.gr-filter-input-wrapper.mb-0
            translate.label Ver
            div.menu
              filter-tree-menu(label-class="" :options="optionsFilterVer" :itemsChecked="optionFilterVer" @checked="onCheckOptionFilterVer" id-key="value" label-key="text")
          div.gr-filter-input-wrapper.mb-0
            translate.label Province
            div.menu
              filter-tree-menu(classFixedMenuVuetify="vMenuFixedTop" label-class="text-capitalize-first-letter" :options="regions" :itemsChecked="regionSelect" @checked="onCheckRegion" id-key="value" label-key="text")
          div.gr-filter-input-wrapper.mb-0
            span.label(v-if="isGenericEntity") #[img.mr-2(:src="img.imgRestaurant")] Tipo de negocio
            span.label(v-else) #[img.mr-2(:src="img.imgRestaurant")] #[translate Type of Restaurant]
            div.menu
              filter-tree-menu(classFixedMenuVuetify="vMenuFixedTop" label-class="" :options="restaurantTypes" :itemsChecked="typeRestaurantSelected" @checked="onCheckRestaurantTypes" id-key="value" label-key="text")
          div.gr-filter-input-wrapper.mb-0
            span.label #[img] #[translate Model]
            div.menu
              filter-tree-menu(classFixedMenuVuetify="vMenuFixedTop" :options="centerModels" :itemsChecked="centerModelsSelected" @checked="onCheckCenterModels" id-key="value" label-key="text")
      #tableRankings(v-if="showRanking")
        v-row.headIndicators.text-left.align-end.mx-0.mt-0

          v-col.d-flex.justify-start.flex-1.colCheck
            <v-checkbox class="mr-2 my-0 py-0" :disabled="!hasPremium" v-model="checkedAll" @change="checkAllCenters" hide-details value :ripple="false"></v-checkbox>
            span Todos
          v-col.d-flex.justify-start.flex-1.colRestaurant
            <svg class="mr-2" :class="{'rotate-180': sortParam==='center' && sortDesc}" @click="setOrdering('center')" xmlns="http://www.w3.org/2000/svg" width="8.121" height="8.819" viewBox="0 0 8.121 8.819">
            <g id="Grupo_9758" data-name="Grupo 9758" transform="translate(-329.439 -285.439)">
            <g id="Grupo_8767" data-name="Grupo 8767" transform="translate(336.5 286.5) rotate(90)">
            <g id="Grupo_8768" data-name="Grupo 8768">
            <line id="Línea_652" data-name="Línea 652" x1="3" y2="3" fill="none" stroke="#608df2" stroke-linecap="round" stroke-width="1.5"/>
            <line id="Línea_760" data-name="Línea 760" x2="3" y2="3" transform="translate(0 3)" fill="none" stroke="#608df2" stroke-linecap="round" stroke-width="1.5"/>
            <path id="Trazado_1354" data-name="Trazado 1354" d="M0,0H7" transform="translate(0.008 3)" fill="none" stroke="#608df2" stroke-linecap="round" stroke-width="1.5"/>
            </g>
            </g>
            </g>
            </svg>

            span(v-if="!isGenericEntity") Restaurantes
            span(v-else) Negocios
          v-col.d-flex.justify-start.flex-1.colBlocked
            <svg class="mr-2" :class="{'rotate-180': sortParam==='orderblocked' && sortDesc}" @click="setOrdering('orderblocked')" xmlns="http://www.w3.org/2000/svg" width="8.121" height="8.819" viewBox="0 0 8.121 8.819">
            <g id="Grupo_9758" data-name="Grupo 9758" transform="translate(-329.439 -285.439)">
            <g id="Grupo_8767" data-name="Grupo 8767" transform="translate(336.5 286.5) rotate(90)">
            <g id="Grupo_8768" data-name="Grupo 8768">
            <line id="Línea_652" data-name="Línea 652" x1="3" y2="3" fill="none" stroke="#608df2" stroke-linecap="round" stroke-width="1.5"/>
            <line id="Línea_760" data-name="Línea 760" x2="3" y2="3" transform="translate(0 3)" fill="none" stroke="#608df2" stroke-linecap="round" stroke-width="1.5"/>
            <path id="Trazado_1354" data-name="Trazado 1354" d="M0,0H7" transform="translate(0.008 3)" fill="none" stroke="#608df2" stroke-linecap="round" stroke-width="1.5"/>
            </g>
            </g>
            </g>
            </svg>
            span Bloqueo
          v-col.d-flex.justify-start.flex-1.colEdit
            span #[translate Editar Ficha]
          v-col.d-flex.justify-center.colNotifications(:class="{'premiumDisabled': !hasPremium}")
            <svg class="mr-2" :class="{'rotate-180': sortParam==='notifications' && sortDesc}" @click="setOrdering('notifications')" xmlns="http://www.w3.org/2000/svg" width="8.121" height="8.819" viewBox="0 0 8.121 8.819">
            <g id="Grupo_9758" data-name="Grupo 9758" transform="translate(-329.439 -285.439)">
            <g id="Grupo_8767" data-name="Grupo 8767" transform="translate(336.5 286.5) rotate(90)">
            <g id="Grupo_8768" data-name="Grupo 8768">
            <line id="Línea_652" data-name="Línea 652" x1="3" y2="3" fill="none" stroke="#608df2" stroke-linecap="round" stroke-width="1.5"/>
            <line id="Línea_760" data-name="Línea 760" x2="3" y2="3" transform="translate(0 3)" fill="none" stroke="#608df2" stroke-linecap="round" stroke-width="1.5"/>
            <path id="Trazado_1354" data-name="Trazado 1354" d="M0,0H7" transform="translate(0.008 3)" fill="none" stroke="#608df2" stroke-linecap="round" stroke-width="1.5"/>
            </g>
            </g>
            </g>
            </svg>
            span #[translate Notificaciones]
          v-col.d-flex.flex-column.colGoogle.colSiteLocalMark
            v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
              template(v-slot:activator="{ on, attrs }")
                button(v-if="!hasTokenGoogle && checkNoErrors && $config.featureConfig['googleToken']" @click="openAuthGoogle")
                  img(:src="img.configGoogle" v-bind="attrs" v-on="on")
              span #[translate Get token]
            v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
              template(v-slot:activator="{ on, attrs }")
                img(:src="img.googleMaps" v-bind="attrs" v-on="on")
              span Google Maps
          v-col.d-flex.colSiteLocalMark.colInstagram.align-center
            v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
              template(v-slot:activator="{ on, attrs }")
                img.mr-2(src="@/assets/img/local/INSTAGRAM.svg" v-bind="attrs" v-on="on")
              span Instagram

          v-col.d-flex.colSiteLocalMark.colFacebook.align-center

            v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
              template(v-slot:activator="{ on, attrs }")
                img.mr-2(:src="img.facebook" v-bind="attrs" v-on="on")
              span Facebook
            v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
              template(v-slot:activator="{ on, attrs }")
                button(v-if="$config.featureConfig['facebookUpdateAll'] && facebookConfig && facebookConfig.app_id" @click="openFacebookBulkUpdate = true")
                  img(:src="img.configFacebook" v-bind="attrs" v-on="on")
              span #[translate Facebook]

          v-col.colSiteLocalMark(:class="{'notExtended': !localMarketingExtended}")
            v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
              template(v-slot:activator="{ on, attrs }")
                img(:src="getImageSiteLocalMark('BIN')" v-bind="attrs" v-on="on")
              span Bing
          v-col.colSiteLocalMark(v-for="(site, keySite) in siteLocalMark"  :class="{'notExtended': !localMarketingExtended}")
            v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
              template(v-slot:activator="{ on, attrs }")
                img(:src="getImageSiteLocalMark(keySite)" v-bind="attrs" v-on="on")
              span {{ site }}


        v-row.bodyIndicators.text-left.mt-3.mx-0(v-if="dataRanking && getUrlEditRestaurant(item.center_id)" v-for="(item, index) in paginated" :class="{'b-top' : index > 0}")
          v-col.d-flex.justify-center.colCheck(:class="{'premiumDisabled': !hasPremium}")
            <v-checkbox class="mr-2 my-0 py-0" :disabled="!hasPremium" v-model="item.edit" @change="controlIsAllChecked" hide-details value :ripple="false"></v-checkbox>
          v-col.flex-1.colRestaurant
            div
              router-link.link-text.text-decoration-none(:to="getUrlEditRestaurant(item.center_id)") {{ item.center }}
            div.flex-basis-100
              span {{ item.locality }}
          v-col.flex-1.colBlocked
            v-tooltip(v-if="item.fully_blocked" right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
              template(v-slot:activator="{ on, attrs }")
                <img src="@/assets/img/bloqueado.svg" width="18" v-bind="attrs" v-on="on" />
              span {{ $gettext('Usted ha bloqueado todos los campos disponibles para este negocio con el objetivo de evitar actualizaciones indeseadas') }}
            v-tooltip(v-if="item.partially_blocked" right color="#222226" open-on-click=true max-width="450" nudge-bottom="0" nudge-right="10" content-class="tooltip-help tooltip-help-rigth")
              template(v-slot:activator="{ on, attrs }")
                <img  src="@/assets/img/medio_lock.svg" width="18" v-bind="attrs" v-on="on" />
              span {{ $gettext('Usted ha bloqueado algunos de los campos disponibles para este negocio con el objetivo de evitar actualizaciones indeseadas') }}
            <img v-if="!item.fully_blocked && !item.partially_blocked" src="@/assets/img/no-locked.svg" width="18" />

          v-col.d-flex.justify-center.colEdit
            span.link-text(@click="goEditRestaurant(item.center_id)")
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <g id="Grupo_11094" data-name="Grupo 11094" transform="translate(-552 -525)">
              <g id="Rectángulo_2676" data-name="Rectángulo 2676" transform="translate(552 525)" fill="#fff" stroke="#608df2" stroke-width="1">
              <rect width="24" height="24" rx="5" stroke="none"/>
              <rect x="0.5" y="0.5" width="23" height="23" rx="4.5" fill="none"/>
              </g>
              <g id="settings" transform="translate(555 528)">
              <path id="Trazado_4953" data-name="Trazado 4953" d="M14.382,11.182a1.2,1.2,0,0,0,.24,1.324l.044.044a1.455,1.455,0,1,1-2.058,2.058l-.044-.044a1.21,1.21,0,0,0-2.051.858v.124a1.455,1.455,0,1,1-2.909,0V15.48a1.2,1.2,0,0,0-.785-1.1,1.2,1.2,0,0,0-1.324.24l-.044.044a1.455,1.455,0,0,1-2.058-2.058l.044-.044a1.21,1.21,0,0,0-.858-2.051H2.455a1.455,1.455,0,1,1,0-2.909H2.52a1.2,1.2,0,0,0,1.1-.785,1.2,1.2,0,0,0-.24-1.324l-.044-.044A1.455,1.455,0,1,1,5.393,3.393l.044.044a1.2,1.2,0,0,0,1.324.24h.058a1.2,1.2,0,0,0,.727-1.1V2.455a1.455,1.455,0,1,1,2.909,0V2.52a1.21,1.21,0,0,0,2.051.858l.044-.044a1.455,1.455,0,1,1,2.058,2.058l-.044.044a1.2,1.2,0,0,0-.24,1.324v.058a1.2,1.2,0,0,0,1.1.727h.124a1.455,1.455,0,1,1,0,2.909H15.48a1.2,1.2,0,0,0-1.1.728Z" fill="#9b9b9b"/>
              <circle id="Elipse_641" data-name="Elipse 641" cx="3" cy="3" r="3" transform="translate(6 6)" fill="#fff"/>
              </g>
              </g>
              </svg>
          v-col.d-flex.justify-center.colNotifications(:class="{'premiumDisabled': !hasPremium}")
            button.relative(@click="openNotificationsForItem(item)" :disabled="!item.notifications")
              ico-notification(:hasInfo="item.notifications")
              span.absolute.numberOfNotifications(v-if="hasPremium && item.notifications") {{ item.notifications }}
            edit-notifications(v-if="item.notifications" :visible="item.showEditNotifications"  @close="item.showEditNotifications=false" :nameCenter="item.center" :idBussines="item.center_id" @refreshSync="getLocalMarketing")




          v-col.d-flex.justify-center.text-center.colGoogle.colSiteLocalMark
            v-tooltip(color="#222226" :class="{'problem': item.accounts.google.status_issue}" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top v-if="item.accounts.google.status_issue")
              template(v-slot:activator="{ on, attrs }")
                span(:class="{'problem': item.accounts.google.status_issue}" v-bind="attrs" v-on="on")
              span {{ item.accounts.google.status }}
            .chartMiniPie(:style="getColorMiniChartPie(item.accounts.google.profile_completed)")
            div.flex-basis-100
              span.percentNumber {{item.accounts.google.profile_completed | formatNumber(0,0)}}%

          v-col.d-flex.justify-center.text-center.colSiteLocalMark.colInstagram
            v-tooltip(color="#222226" :class="{'problem': item.accounts.facebook.status_issue}" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top v-if="item.accounts.facebook.status_issue")
              template(v-slot:activator="{ on, attrs }")
                span(:class="{'problem': item.accounts.facebook.status_issue}" v-bind="attrs" v-on="on")
              span {{ item.accounts.facebook.status }}

            v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
              template(v-slot:activator="{ on, attrs }")
                <img v-if="(item.accounts.facebook.page_id || item.accounts.facebook.fb_page_url) && item.accounts.facebook.have_instagram" v-bind="attrs" v-on="on" src="@/assets/img/local/connected.svg" />
                <img v-else src="@/assets/img/local/noconnected.svg" v-bind="attrs" v-on="on" />
              span(v-if="(item.accounts.facebook.page_id || item.accounts.facebook.fb_page_url) && item.accounts.facebook.have_instagram") {{ item.accounts.facebook.instagram_user_name }}
              span(v-else) Sin cuenta
          v-col.d-flex.justify-center.text-center.colSiteLocalMark.colFacebook
            v-tooltip(color="#222226" :class="{'problem': item.accounts.facebook.status_issue}" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top v-if="item.accounts.facebook.status_issue")
              template(v-slot:activator="{ on, attrs }")
                span(:class="{'problem': item.accounts.facebook.status_issue}" v-bind="attrs" v-on="on")
              span {{ item.accounts.facebook.status }}

            v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
              template(v-slot:activator="{ on, attrs }")
                <img v-if="(item.accounts.facebook.page_id || item.accounts.facebook.fb_page_url) && item.accounts.facebook.raw_status == 'Active'" v-bind="attrs" v-on="on" src="@/assets/img/local/connected.svg" />
                <img v-else src="@/assets/img/local/noconnected.svg" v-bind="attrs" v-on="on" />
              span(v-if="(item.accounts.facebook.page_id || item.accounts.facebook.fb_page_url) && item.accounts.facebook.raw_status == 'Active'") {{ item.accounts.facebook.fb_page_url }}
              span(v-else) Sin cuenta
          v-col.colSiteLocalMark(:class="{'notExtended': !localMarketingExtended}")
            v-tooltip(color="#222226" open-on-click=true content-class="tooltip-help tooltip-help-rigth" top)
              template(v-slot:activator="{ on, attrs }")
                <img v-if="item.accounts.bing.url" v-bind="attrs" v-on="on" src="@/assets/img/local/connected.svg" />
                <img v-else src="@/assets/img/local/noconnected.svg" v-bind="attrs" v-on="on" />
              span(v-if="item.accounts.bing.url") {{ item.accounts.bing.url }}
              span(v-else) Sin cuenta

          v-col.colSiteLocalMark(v-for="(site, keySite) in siteLocalMark" :class="{'notExtended': !localMarketingExtended}")
            <img src="@/assets/img/local/noconnected.svg" />

      v-row.headTitleGrid.mx-3(v-if="!showRanking")
        v-col.titleGrid.other(md="4")
          span {{ options[selectGoogle].name }}
        v-col.titleInfo(md="6")
        v-col.iconInfo.text-right(md="2")
      v-row(v-if="loadingLocations")
        v-col.col-12
          div.flex.justify-center.items-center
            span.gr-loader-spinner.ma-auto
      div(v-else)
        #tablePending.mx-5(v-if="!showRanking")
          v-row.headIndicators.text-left.align-center
            v-col.col-2
              span {{ $gettext('Company') }}
            v-col.d-flex.align-center.col-6
              span {{ $gettext('Address') }}
            v-col.d-flex.align-center.col-4
              span {{ $gettext('Actions') }}

          v-row.bodyIndicators.text-left.align-center.mt-3(v-for="(item, index) in options[selectGoogle].locations" :key="index")
            v-col.col-2
              span {{ item.business_name }}
            v-col.d-flex.align-center.col-6
              span {{ item.business_address }}
            v-col.d-flex.align-center.col-4
              select(v-model="item.centerSelected" :disabled="item.status || item.linked")
                option(v-for="(option) in centers" :value="option.id") {{ option.name }} - {{ option.address }}, {{ option.locality }} - {{ option.region }}
              button.action-button(v-if="item.linked")
                img(:src="img.googleMaps")
                span #[translate Linked]
              button.action-button(v-else-if="!item.status" :class="{ disabled: !item.centerSelected }" @mousedown="link(item)")
                img(:src="img.googleMaps")
                span #[translate Set up]
              button.action-button(v-else)
                div.gr-loader-spinner-inline
                  span.gr-loader-spinner

      facebook-locations(v-if="facebookConfig && facebookConfig.app_id && center" @update="updateList" :selected.syc="facebookSelected" :center.syc="center" :current-facebook-linkeded="currentFacebookLinkeded" :app-id="facebookConfig.app_id" :api-version="facebookConfig.api_version" :open.sync="openFacebookLocations")
      facebook-bulk-update(v-if="facebookConfig && facebookConfig.app_id" @update="updateList" :current-facebook-linkeded="currentFacebookLinkeded" :app-id="facebookConfig.app_id" :api-version="facebookConfig.api_version" :open.sync="openFacebookBulkUpdate")
      edit-multiple(:visible="showEditMultiple"  @close="showEditMultiple=false; actionOpenModal='default'" :idsBussines="idsBussinesChecked" )
      add-event(:visible="showCreateEventMultiple"  @close="showCreateEventMultiple=false; actionOpenModal='default'" :idsBussinesModeMultiple="idsBussinesChecked" )
      add-quotation(:visible="showCreateOfferMultiple"  @close="showCreateOfferMultiple=false; actionOpenModal='default'" :idsBussinesModeMultiple="idsBussinesChecked" )
      add-new(:visible="showCreateStandardMultiple"  @close="showCreateStandardMultiple=false; actionOpenModal='default'" :idsBussinesModeMultiple="idsBussinesChecked")
      add-photos(:visible="showEditMultipleUploadImages"  @close="showEditMultipleUploadImages=false; actionOpenModal='default'" :idsBussinesModeMultiple="idsBussinesChecked" @successAction="showEditMultipleUploadImages=false" )
      edit-configuration-blocked(:visible="showEditMultipleBlockedSection" @close="showEditMultipleBlockedSection=false; actionOpenModal='default'" :idsBussinesModeMultiple="idsBussinesChecked")
      edit-google-special-hours-multiple(:visible="showEditMultipleGoogleSpecialHours" @close="showEditMultipleGoogleSpecialHours=false; actionOpenModal='default'" :idsBussinesModeMultiple="idsBussinesChecked")
      add-video(:visible="showEditMultipleGoogleVideo"  @close="showEditMultipleGoogleVideo=false; actionOpenModal='default'" :idsBussinesModeMultiple="idsBussinesChecked" )
</template>
<script>
import SkeletonCard from "@/components/common/skeletonCard.vue";
import SrvGlobal from "@/services/local.js";
import commonMixins from "@/mixins/common.js";
import localMixins from "@/mixins/local.js";
import brandMixin from "@/mixins/brand.js";
import {mapGetters} from "vuex";
import FacebookLocations from "@/components/local/FacebookLocations.vue";
import FacebookBulkUpdate from "@/components/local/FacebookBulkUpdate.vue";
import editNotifications from "@/components/local/editNotifications.vue";
import editMultiple from "@/components/local/editMuliple.vue";
import IcoNotification from "@/components/local/IcoNotification.vue";
import addEvent from "@/components/local/posts/addEvent.vue";
import addQuotation from "@/components/local/posts/addQuotation.vue";
import addNew from "@/components/local/posts/addNew.vue";
import addService from "@/components/local/services/add.vue";
import addPhotos from "@/components/local/editPhotosMultiple.vue";
import editConfigurationBlocked from "@/components/local/editConfigurationBlocked.vue";
import Breadcrumbs from "@/components/common/Breadcrumbs.vue";
import RankingLevelGroup from "@/components/local/RankingLevelGroup.vue";
import FilterTreeMenu from "@/components/filters/filterTreeMenu";
import editGoogleSpecialHoursMultiple from "@/components/local/editGoogleSpecialHoursMultiple.vue";
import addVideo from "@/components/local/editVideoMultiple.vue";

export default {
  components: {
    FilterTreeMenu, RankingLevelGroup, Breadcrumbs, FacebookBulkUpdate, FacebookLocations,
    SkeletonCard, editNotifications, IcoNotification, editMultiple, addEvent, addQuotation, addNew,
    editConfigurationBlocked, addPhotos, addService, editGoogleSpecialHoursMultiple, addVideo
  },
  mixins: [commonMixins, localMixins, brandMixin],
  data() {
    return {
      loadingLocations: false,
      showEditNotifications: false,
      actionOpenModal: 'default',
      dataProfile: [],
      dataRanking: [],
      siteLocalMark: [],
      hasTokenGoogle: false,
      checkNoErrors: false,
      showEditMultiple: false,
      showEditMultiplePost: false,
      showEditMultipleTypePost: false,
      showEditMultipleBlockedSection: false,
      showEditMultipleGoogleSpecialHours: false,
      showEditMultipleService: false,
      showEditMultipleUploadImages: false,
      showCreateEventMultiple: false,
      showCreateStandardMultiple: false,
      showCreateOfferMultiple: false,
      showAddPhotosMultiple: false,
      showEditMultipleGoogleVideo: false,
      checkedAll: false,
      urlAuthGoogle: "",
      authCodeGoogle: "",
      current: 1,
      pageSize: 20,
      options: [{name: "Loading..."}],
      selectGoogle: 0,
      img: {
        googleMaps: require("@/assets/img/google-maps-icon.svg"),
        configGoogle: require("@/assets/img/local/config_google.svg"),
        configFacebook: require("@/assets/img/local/config_facebook.svg"),
        facebook: require("@/assets/img/facebook.svg"),
      },
      filters: {
        nameItem: "",
      },
      sortParam: "center",
      sortDesc: true,
      loading: true,
      isLoadingGoogleUserLocations: false,
      centers: [],
      centerSelected: [],
      openFacebookLocations: false,
      openFacebookBulkUpdate: false,
      facebookConfig: {
        app_id: undefined,
        api_version: undefined,
      },
      center: undefined,
      facebookSelected: undefined,
      optionsFilterVer: [
        {
          value: 6,
          text: "Enlazado API",
        },
        {
          value: 0,
          text: "Incidencias API",
        },
        {
          value: 1,
          text: "Bloqueados completos",
        },
        {
          value: 2,
          text: "Bloqueados parcial",
        },
        {
          value: 3,
          text: "Sin bloquear",
        },
        {
          value: 4,
          text: "Sin notificaciones",
        },
        {
          value: 5,
          text: "Con notificaciones",
        },
      ],
      optionFilterVer: [],
      optionsMultipleGoogle: [
        {
          key: "editdata",
          name: "Editar datos/horarios",
        },
        {
          key: "blockedsections",
          name: "Bloquear secciones",
        },
        {
          key: "addservices",
          name: "Añadir servicios",
        },
        {
          key: "addevent",
          name: "Subir post - evento",
        },
        {
          key: "addquotation",
          name: "Subir post - Oferta",
        },
        {
          key: "addnew",
          name: "Subir post - Publicación",
        },
        {
          key: "addimages",
          name: "Subir imágenes",
        },
        {
          key: "googlespecialhours",
          name: "Editar días con horario diferente"
        },
        {
          key: "addvideo",
          name: "Subir video"
        }
      ],
    };
  },
  async mounted() {
    this.scroll();
    this.loading = true;
    if (this.$store.getters.getNode > 0) {
      if (this.typeNode !== "group") {
        await this.getTokenGoogle();
      }
      await this.getLocalMarketing();
      if (this.typeNode !== "group") {
        await this.getCurrentFacebookLinkeded();
      }
      this.loading = false;
      if (this.hasTokenGoogle && this.typeNode !== "group") {
        this.isLoadingGoogleUserLocations = true;
        await this.getGoogleUserLocations();
        this.isLoadingGoogleUserLocations = false;
      }
    }
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
    centersCheckdWithOutServices() {
      return this.dataRanking.filter((e) => this.idsBussinesChecked.includes(e.center_id) && !e.can_modify_services_list);
    },
    labelSearch() {
      return this.isGenericEntity ? this.$gettext("Buscar negocio") : this.$gettext("Buscar restaurante")
    },
    idsBussinesChecked() {
      return this.dataRanking.filter((e) => e.edit).map((e) => e.center_id);
    },

    showRanking() {
      return this.selectGoogle === 0 ? true : false;
    },
    paginated() {
      let filtered = false;
      let arrFiltered = this.dataRanking?.slice();
      if (this.filters.nameItem.toLowerCase().trim()) {
        arrFiltered = arrFiltered.filter((c) => c.center.toLowerCase().indexOf(this.filters.nameItem.toString().toLowerCase().trim()) > -1);
        filtered = true;
        arrFiltered = this.sortArray(arrFiltered);
      }

      if (!filtered) {
        arrFiltered = this.sortArray(arrFiltered);
      }

      if (this.optionFilterVer.length > 0) {
        arrFiltered = arrFiltered.filter((c) => {
          const conditions = {
            0: () =>
              c.accounts &&
              (c.accounts.facebook.status_issue ||
                c.accounts?.google?.status_issue ||
                c.accounts?.google?.raw_linked === "Disconnected" ||
                c.accounts?.google?.raw_linked === "Unlinked"),
            1: () => c.fully_blocked,
            2: () => c.partially_blocked,
            3: () => !c.partially_blocked && !c.fully_blocked,
            4: () => !c.notifications,
            5: () => c.notifications,
            6: () => c?.accounts?.google?.raw_linked !== 'Unlinked'
          };

          return this.optionFilterVer.some((e) => conditions[e]?.());
        });
      }
      return arrFiltered;
    },
  },
  watch: {
    filterApply: function () {
      this.getTokenGoogle();
      this.getLocalMarketing();
    },
    async selectGoogle(id) {
      if (id > 0) {
        const locationCode = this.options[id]["account_name"];
        if (Object.entries(this.options[id]["locations"]).length === 0) {
          await this.getGoogleUserLocations(locationCode);
        }
      }
    },
  },
  methods: {
    setOrdering(field) {
      if (this.sortParam === field) {
        this.sortDesc = !this.sortDesc
      }
      this.sortParam = field
    },
    onCheckOptionFilterVer(event) {
      this.optionFilterVer = event.map((item) => item.value);
    },
    controlIsAllChecked() {
      let all = true;
      this.dataRanking.forEach((e) => {
        if (!e.edit) {
          all = false;
        }
      });
      if (all) {
        this.checkedAll = true;
      } else {
        this.checkedAll = false;
      }
    },
    checkAllCenters(event) {
      this.dataRanking.map((e) => (e.edit = event));
    },
    openNotificationsForItem(item) {
      if (this.hasPremium) {
        item.showEditNotifications = true;
      }
    },
    openModalMultiple(event) {
      let key = event.target.value;
      if (this.hasPremium && this.dataRanking.filter((e) => e.edit).length > 0) {
        if (key === "editdata") {
          this.showEditMultiple = true;
        }
        if (key === "blockedsections") {
          this.showEditMultipleBlockedSection = true;
        }
        if (key === "addservices" && this.dataRanking.some((e) => e.can_modify_services_list)) {
          this.showEditMultipleService = true;
        }
        if (key === "addimages") {
          this.showEditMultipleUploadImages = true;
        }
        if (key === "addevent") {
          this.showCreateEventMultiple = true;
        }
        if (key === "addquotation") {
          this.showCreateOfferMultiple = true;
        }
        if (key === "addnew") {
          this.showCreateStandardMultiple = true;
        }
        if (key === "googlespecialhours") {
          this.showEditMultipleGoogleSpecialHours = true;
        }
        if (key === "addvideo") {
          this.showEditMultipleGoogleVideo = true;
        }
      }
    },
    openEditMultipleUploadPost() {
      if (this.hasPremium && this.dataRanking.filter((e) => e.edit).length > 0) {
        this.showEditMultipleTypePost = true;
      }
    },

    updateList(data) {
      data.result.forEach((item) => {
        if (item.success) {
          let data = this.dataRanking.find((data) => data.center_id === item.center_id);
          data && this.$set(data.accounts, "facebook", item.facebook_status);
        }
      });
    },
    updateListGoogle(data) {
      if (data.success) {
        let data = this.dataRanking.find((item) => item.center_id === data.center_id);
        data && this.$set(data.accounts, "google", data.google_status);
      }
    },
    openAuthGoogle() {
      let windowObjectReference = null;
      let previousUrl = null;

      const popupCenter = ({url, title, w, h}) => {
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft;
        const top = (height - h) / 2 / systemZoom + dualScreenTop;
        const newWindow = window.open(
          url,
          title,
          `
          scrollbars=yes,
          width=${w / systemZoom},
          height=${h / systemZoom},
          top=${top},
          left=${left}
          toolbar=no,
          menubar=no
        `
        );

        if (window.focus) newWindow.focus();
        return newWindow;
      };

      const openSignInWindow = (url, title) => {
        const w = 600;
        const h = 700;
        if (windowObjectReference === null || windowObjectReference.closed) {
          windowObjectReference = popupCenter({url, title, w, h});
        } else if (previousUrl !== url) {
          windowObjectReference = popupCenter({url, title, w, h});
          windowObjectReference.focus();
        } else {
          windowObjectReference.focus();
        }
        previousUrl = url;
      };

      let node = ``;

      if (this.$store.getters.getNodeLevel === 9) {
        if (this.$store.getters.getNode) {
          node += `?center=${this.$store.getters.getNode}`;
        }
      } else {
        if (this.$store.getters.getNode) {
          node += `?node=${this.$store.getters.getNode}`;
        }
      }

      window.localStorage.setItem("nodeForGoogle", node);

      openSignInWindow(this.urlAuthGoogle, "Get Token");
    },
    async getTokenGoogle() {
      const {status, data} = await SrvGlobal.getHasToken();
      if (status === 200) {
        if (data.has_valid_token) {
          this.hasTokenGoogle = true;
        } else {
          this.urlAuthGoogle = data.url;
          this.authCodeGoogle = data.code;

          if (!data.error) {
            this.checkNoErrors = true;
          }
        }
      }
    },
    async getCurrentFacebookLinkeded() {
      const {status, data} = await SrvGlobal.getCurrentFacebookLinkeded();
      if (status === 200) {
        this.currentFacebookLinkeded = data.face_centers;
      }
    },
    async getLocalMarketing() {
      const {status, data} = await SrvGlobal.getLocalMarketing();
      if (status === 200) {
        this.current = 1;
        this.dataProfile = data.profile_rest;
        if (data.ranking) {
          data.ranking.map((e) => {
            e.orderblocked = 1
            if (e.partially_blocked) {
              e.orderblocked = 2
            }

            if (e.fully_blocked) {
              e.orderblocked = 3
            }
            e.showEditNotifications = false;
            e.edit = false;
            e.uploadPost = false;
            e.uploadImages = false;
            e.photosSection = false;
          });
        }
        // console.log({ ranking: data.ranking });
        window.ranking = data.ranking
        this.dataRanking = data.ranking;
        this.facebookConfig = data.facebook_config;
        this.siteLocalMark = {};
        let keySitesView = ["MAP"];
        if (this.isAdverbia) {
          keySitesView = ["MAP"];
        }
        Object.entries(data.sitelocalmark).forEach((e) => {
          if (keySitesView.includes(e[0])) {
            this.siteLocalMark[e[0]] = e[1];
          }
        });
      }
    },
    sortArray(arrFiltered) {
      arrFiltered = arrFiltered?.sort((a, b) => {
        let alower
        let blower
        if (typeof a[this.sortParam] === 'string') {
          alower = a[this.sortParam].toLowerCase()
        } else {
          alower = a[this.sortParam]
        }
        if (typeof b[this.sortParam] === 'string') {
          blower = b[this.sortParam].toLowerCase()
        } else {
          blower = b[this.sortParam]
        }
        if (alower < blower) return -1;
        if (alower > blower) return 1;
        return 0;

      });

      if (!this.sortDesc) {
        arrFiltered = arrFiltered?.reverse();
      }
      return arrFiltered;
    },
    next() {
      this.current++;
    },
    scroll() {
      if (this.selectGoogle === 0) {
        let wrapper = document.getElementById("app");
        let bottomOfWindow = false;
        window.onscroll = () => {
          if (parseInt(document.documentElement.scrollTop) + window.innerHeight === wrapper.offsetHeight || parseInt(document.documentElement.scrollTop) + 1 + window.innerHeight === wrapper.offsetHeight) {
            bottomOfWindow = true;
          } else {
            bottomOfWindow = false;
          }
          if (bottomOfWindow) {
            this.current++;
          }
        };
      }
    },
    async getGoogleUserLocations(locationCode) {
      this.loadingLocations = true;
      const {status, data} = await SrvGlobal.getGoogleUserLocations(locationCode);
      if (status === 200) {
        if (data.google_business) {
          if (locationCode) {
            const options = data.google_business.map((item) => ({...item, centerSelected: ""}));
            const folder = options.filter((item) => item["account_name"] === locationCode);
            if (folder[0]) {
              this.options[this.selectGoogle].locations = folder[0].locations;
            }
          } else {
            if (this.isGenericEntity) {
              data.google_business.unshift({name: "Negocios Pendientes en Google"});
            } else {
              data.google_business.unshift({name: "Restaurantes Pendientes en Google"});
            }
            this.options = data.google_business.map((item) => ({...item, centerSelected: ""}));
          }
        }

        if (data.centers) {
          this.centers = data.centers;
        }
      }
      this.loadingLocations = false;
    },
    link(item) {
      this.$set(item, "status", true);
      this.$nextTick(() => {
        SrvGlobal.linkGoogle(item.centerSelected, item.google_name).then(
          (res) => {
            if (res.status === 200) {
              this.$set(item, "linked", true);
              this.$set(item, "status", false);
              res.data.center_id = item.centerSelected;
              this.updateListGoogle(res.data);
            }
          },
          () => {
          }
        );
      });
    },
  },
};
</script>
<style scoped>
.boxActionMultipleBySite {
  background-color: #fff;
  border-radius: 4px;
  width: max-content;
}

.boxProfileComplete {
  height: 71px;
}

.breads {
  height: 47px;
}
</style>
