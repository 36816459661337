<template lang="pug">
  div
    div.gridFull.pa-0
      v-row.flex-center.b-bottom.mb-0.no-gutters.main(:style="`top: ${headerHeight}px`")
        v-col.py-4.d-flex.items-center(md="4")
          .block-count.pl-4.font-weight-med
            span.mr-1(v-if="configs.length" ) {{configs.length}}
            span.mr-1(v-else) 0
            translate configuraciones
          button.primary-button(@click="showEditionModal=true")
            span.text-13 #[translate Añadir una Configuración]
        v-col(md="8")
          div.filters
            span.labelFilter #[translate Buscar restaurante]:
              div.filter.ml-2.mr-4
                input.filterBySearch(type="text" v-model="search")
                button.filterBySearchButton.clean(@click="cleanBySearch" v-if="$route.query.search || search")
                  img(:src="img.clean")
                button.filterBySearchButton(@click="filterBySearch")
                  img(:src="img.search")

            span.labelFilter.d-flex.items-center #[translate Nº estrellas]:
              div.filter.ml-2.mr-4
                filter-tree-menu(:options="starsNumber" label-class="text-capitalize-first-letter" :itemsChecked="selectedStarsMenu" @checked="onCheckStars" id-key="id" label-key="name")
            span.labelFilter.d-flex.items-center #[translate Texto]:
              div.filter.ml-2.mr-4
                filter-tree-menu(:options="textOptions" label-class="text-capitalize-first-letter" :itemsChecked="selectedTextMenu" @checked="onCheckText" id-key="id" label-key="name")
      v-row.no-gutters
        div.gr-loader-snipper--wrapper(v-if="loading")
          span.gr-loader-snipper
        automatic-answers-table(style="width:100%" :search="this.search" :configs="configs" :loading="loading" @onEdit="handleEditButton" @onDelete="handleDeleteButton")

    new-a-i-configuration-modal(v-if="showEditionModal" :isActive="showEditionModal" @onHidden="handleHidden" :initial-data="initialData" @handle-creation="handleNewItem" @handle-edition="handleEdition")
    modal-confirm-delete(:visible="showDeleteModal" @close="showDeleteModal=false" @confirmed="confirmDelete" :message="deleteMessage")
</template>

<script>
import AutomaticAnswersTable from "@/components/feedback/AutomaticAnswersTable.vue"
import NewAIConfigurationModal from "@/components/feedback/NewAIConfigurationModal.vue"
import FilterTreeMenu from "@/components/filters/filterTreeMenu";
import SrvFeedback from "@/services/feedback";
import ModalConfirmDelete from "@/components/local/confirmDelete.vue"

export default {
  props: {},
  components: {AutomaticAnswersTable, FilterTreeMenu, NewAIConfigurationModal, ModalConfirmDelete},
  data() {
    return {
      showEditionModal: false,
      search: "",
      configs: [],
      img: {
        search: require('@/assets/img/buscar.svg'),
        clean: require('@/assets/img/borrar.svg')
      },
      starsNumber: [{id: 1, name: "1-2 estrella"}, {id: 2, name: "3 estrellas"}, {id: 3, name: "4 estrellas"}, {id: 4, name: "5 estrellas"}],
      textOptions: [{id: true, name: "Con texto"}, {id: false, name: "Sin texto"}],
      selectedStarsMenu: [],
      selectedTextMenu: [],
      hasText: null,
      stars: [],
      initialData: null,
      showDeleteModal: false,
      deleteId: null,
      loading: false,
      deleteMessage: this.$gettext('¿Estás seguro de que desea eliminar esta configuración?')
    }
  },
  computed: {
    headerHeight() {
      return this.$store.getters.getTopSectionHeight - 2
    },
  },
  mounted() {
    this.getAutomaticAnswersConfigs()
    const node = this.$store.getters.getNode
    const activeNode = document.querySelector(".tree-active");
    console.log({activeNode: activeNode.dataset})
    console.log({node, activeNode})
  },
  methods: {
    async confirmDelete() {
      try {
        this.loading = true
        await SrvFeedback.deleteAutomaticAnswer(this.deleteId)
        this.configs = this.configs.filter(item => item.id !== this.deleteId)
      } catch (error) {
        console.error("[ERROR_confirmDelete]:", error)
      } finally {
        this.deleteId = null;
        this.loading = false
      }
    },
    handleDeleteButton(id) {
      console.log({id})
      this.showDeleteModal = true
      this.deleteId = id
    },
    handleNewItem(item) {
      this.configs = [...this.configs, item]
    },
    handleEdition(item) {
      const index = this.configs.findIndex(config => config.id === item.id)

      if (index > 0) {
        this.configs = this.configs.map((config) => {
          if (config.id === item.id) {
            const formatedStars = item.ratings.map(item => {
              if (item === "2-1") return "1"
              return (item - 1).toString()
            })
            return {...item, ratings: formatedStars}
          }
          return config
        })
      }

    },
    handleEditButton(item) {
      const formatedStars = item.ratings.map(item => {
        if (item === "2-1") return "1"
        return (item - 1).toString()
      })
      this.initialData = {...item, ratings: formatedStars}
      this.showEditionModal = true
    },
    onCheckText(selected) {
      if (selected.length === 1) {
        this.hasText = selected?.[0].value
      } else {
        this.hasText = null
      }

      this.getAutomaticAnswersConfigs()
    },
    onCheckStars(selected) {
      this.stars = selected.map((item) => item.value)

      this.getAutomaticAnswersConfigs()
    },
    handleHidden() {
      this.showEditionModal = !this.showEditionModal
      if (!this.showEditionModal) {
        this.initialData = null
      }
    },
    cleanBySearch() {
      this.search = ''
    },
    filterBySearch() {
      this.getAutomaticAnswersConfigs()
    },
    async getAutomaticAnswersConfigs() {
      try {
        const {data: {results}} = await SrvFeedback.getAutomaticAnswersConfigs({search: this.search, hasText: this.hasText, stars: this.stars})
        this.configs = results.map(result => {
          const formatedStars = result.ratings.map(item => {
            if (item === 1) return "1-2"
            return item + 1
          })
          return {...result, ratings: formatedStars}
        })
      } catch (error) {
        // console.error("[ERROR: getAutomaticAnswersConfigs]:", error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.primary-button {
  background-color: #00ADFC;
  padding: 10px 20px;
  margin-left: 46px;
  border-radius: 4px;

  span {
    color: white;
  }
}

.filterBySearch {
  vertical-align: middle;
  font-size: 0.75rem;
  border: 1px solid #D7DAE2;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 0;
  background: white;
  color: #1976d2;
  height: 34px;
  padding-left: 16px;
}

.filterBySearchButton {
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #D7DAE2;
  border-left: 0;
  height: 34px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 10px;

  &.clean {
    border-right: 1px solid #D7DAE2;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  img {
    width: 14px;
    margin-top: 5px;
  }
}

.main {
  position: sticky;
  z-index: 2;
  background: #fff;
  overflow-y: hidden;
}

.action-button {
  background-color: #EBEBEB;
  border-radius: 2px;
  max-width: fit-content;
}
</style>
