<template lang="pug">
  div
    v-dialog.dialogEdit(v-model="show"
      width="680")
      v-card.dialogEdit
        v-card-text.pb-0
        v-container
          div.gr-loader-snipper--wrapper(v-if="loading")
            span.gr-loader-snipper
          v-row#headerModalEdit.pt-3
            v-col(cols="10")
              strong Advertencia
            v-col.mt-5(cols="2")
              img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
          v-row#bodyModalEdit
            v-col(cols="12")
              v-container
                v-row
                  v-col.text-left.pt-6.pb-4(cols="12")
                    p(v-if="message") {{message}}
                    p(v-else) #[translate ¿Estás seguro de que desea eliminar esta tarea?]
          v-row#footerModalEdit
            v-col(cols="12")
              button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
              button.btnSaveEditModal(@click="save()")  #[translate Confirmar]
</template>
<script>
import commonMixins from '@/mixins/common.js'

export default {
  mixins: [commonMixins],
  props: ['visible', 'idBussines', 'isDetails', 'message'],
  data() {
    return {
      loading: false,
      imgClose: require('@/assets/img/ico-close-edit.svg'),

    }
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.data = {}
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    save() {
      this.$emit('confirmed')
      this.$emit('close')
    }

  }
}
</script>

