// Importamos mutaciones
import * as types from '@/store/mutation-types'

const feedback = {
  state: {
    opinionsTypesSelected: [],
    opinionsCategoriesSelected: [],
    opinionsSitesSelected: [],
    pending: false,
    opinions: [],
    search: null
  },
  mutations: {
    [types.FEEDBACK_FILTER_OPINIONS_TYPES_SELECTED](state, data) {
      state.opinionsTypesSelected = data
    },
    [types.FEEDBACK_FILTER_OPINIONS_CATEGORIES_SELECTED](state, data) {
      state.opinionsCategoriesSelected = data
    },
    [types.FEEDBACK_FILTER_OPINIONS_SITES_SELECTED](state, data) {
      state.opinionsSitesSelected = data
    },
    [types.FEEDBACK_UPDATE](state, data) {
      state.pending = data
    },
    [types.FEEDBACK_OPINIONS](state, data) {
      state.opinions = data
    },
    [types.FEEDBACK_OPINIONS_SEARCH](state, data) {
      state.search = data
    },
  },
  getters: {
    'getOpinionsTypesSelected': state => {
      return state.opinionsTypesSelected
    },
    'getOpinionsCategoriesSelected': state => {
      return state.opinionsCategoriesSelected
    },
    'getOpinionsSitesSelected': state => {
      return state.opinionsSitesSelected
    },
    'getOpinionsSearch': state => {
      return state.search
    },
  }
}

export default feedback
