<template>
  <div class="mb-4" :class="{'premiumDisabled': !hasPremium}">
    <div v-if="!isStaff">
      <div v-if="!answers">
        <strong>Solicitar 3 respuestas a chatGPT</strong>
        <button @click="getDataChatGPTOriginal()" class="btnGetAnwersChatGpt btnWithLoading">
          <div class="gr-loader-snipper--wrapper" v-if="loading">
            <span class="gr-loader-snipper"></span>
          </div>
          PEDIR
        </button>
      </div>
      <div v-else>
        <strong>Sugerencia de respuesta de chatGPT</strong>
        <div class="d-flex answerChatGpt" v-for="(answer, index) in answers" :key="index">
          <div class="flex-1 pa-2 text">{{ answer }}</div>
          <div class="">
            <button class="btnGetAnwersChatGpt select" @click="setAnswer(answer)">Seleccionar</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="!item.has_ai_config">
        <div class="border-sm border-[#1778d1] p-4 outlined-button cursor-pointer" @click="navigateToAIConfig">
          <strong>Configurar respuestas automáticas</strong>
        </div>
      </div>
      <div v-if="loading">
        <v-skeleton-loader v-for="n in 3" :key="n"
                           class="my-2 align-left"
                           elevation="2"
                           max-width="400"
                           type="list-item-two-line"/>
      </div>
      <div v-else-if="answers && answers.length > 0">
        <strong>Sugerencia de respuesta usando configuración automática</strong>
        <div class="d-flex answerChatGpt" v-for="(answer, index) in answers" :key="index">
          <div class="flex-1 pa-2 text">{{ answer }}</div>
          <div class="">
            <button class="btnGetAnwersChatGpt select" @click="setAnswer(answer)">Seleccionar</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import SrvFeedback from '@/services/feedback.js'
import Common from "@/mixins/common";


export default {
  props: ['item', 'hasPremium'],
  mixins: [Common],
  data() {
    return {
      answers: null,
      loading: false
    }
  },
  watch: {
    item: {
      handler() {
        if (this.item.has_ai_config) {
          this.getDataChatGPT(this.item)
        }
      },
      deep: true
    }
  },
  methods: {
    navigateToAIConfig() {
      this.$router.push({name: 'AutomaticAnswers', params: {paramCOD: this.$route.params.paramCOD}, query: this.$route.query})
    },
    //DELETE
    async getDataChatGPTOriginal() {
      if (this.hasPremium) {
        if (!this.loading) {
          this.loading = true
          const res = await SrvFeedback.getAnswersChatGPT({review: this.item.text, n: 3})
          if (res.status === 200) {
            this.answers = res.data.response
          }
          this.loading = false
        }
      }
    },
    async getDataChatGPT(item) {
      if (this.hasPremium) {
        if (!this.loading) {
          this.loading = true
          const res = await SrvFeedback.getAutomaticsAnswers({reviewId: item.id})
          if (res.status === 200) {
            this.answers = res?.data?.data?.ai_suggestions
          }
          this.loading = false
        }
      }
    },
    setAnswer(text) {
      if (this.hasPremium)
        this.$emit('setAnswer', {item: this.item, text: text})
    }
  }
}
</script>
<style lang="scss" scoped>
.align-left {
  margin-left: 0;
  margin-right: auto;
}

.outlined-button {
  color: #1778d1;
  border: 1.5px solid #1778d1;
  max-width: fit-content;
  padding: 8px;
  border-radius: 4px;
}

.btnGetAnwersChatGpt {
  background-color: #1778d1;
  color: #fff;
  padding: 2px 10px;
  margin-left: 5px;
  position: relative;

  &.select {
    background-color: #fff;
    color: #1778d1;
    border: 1px solid #1778d1;
  }
}

.answerChatGpt {

  margin-bottom: 5px;
  align-self: flex-start;

  .text {
    background-color: #f3f1f1;
  }
}
</style>
