<template lang="pug">
  div
    success-state(v-if="showSuccess" :visible="showSuccess" @close="showSuccess=false" @confirmed="showSuccess=false")
    error-state(v-else-if="showError" :visible="showError" @close="showError=false" @confirmed="showError=false")
    v-dialog.dialogEdit(v-else v-model="show"
      width="680")
      v-card.dialogEdit
        v-card-text.pb-0
        v-container
          div.gr-loader-snipper--wrapper(v-if="loading")
            span.gr-loader-snipper
          v-row#headerModalEdit.pt-3
            v-col(cols="10")
              span(v-if="isEditionMode") #[translate Editar una oferta]
              span(v-else) #[translate Añadir una oferta]
            v-col.mt-5(cols="2")
              img.close-modal.cursor-pointer(:src="imgClose" @click.stop="show=false")
          v-row#bodyModalEdit
            v-col(cols="12")
              <Errors v-if="errors" :errors="errors" />
            v-col(cols="6")
              v-container
                v-row
                  v-col.text-left.pt-6.pb-4(cols="12" v-if="!saveStatus")
                    div.messageError
                      span {{ $gettext('An error occurred while recording.') }}
                  v-col.text-left.pt-6.pb-0(cols="12")
                    span #[translate Título de la oferta ]
                    span.text-red *
                    input.inputFullEditModal.rounded(type="text" v-model="data.eventName")
                    span.text-12.text-red(v-if="errorEventName") Campo obligatorio
                  v-col.text-left.pb-0(cols="12")
                    span #[translate Fecha de Inicio ]
                    span.text-red *
                    <div class="d-flex">
                    <DatePicker v-model="data.startDate" @change="controlDates" class="w-full flex-1 mr-2 max-w-auto rounded" :disabled-date="disabledBeforeTodayAndAfterAWeek"  :open.sync="showDateInit"></DatePicker>
                    <label @click="showDateInit = true">
                    <img :src="imgCalendar" class="icoCalendar" />
                    </label>
                    </div>
                    span.text-12.text-red(v-if="errorStartDate") Campo obligatorio
                  v-col.text-left.pb-0(cols="12")
                    span #[translate Fecha de Fin ]
                    span.text-red *
                    <div class="d-flex">
                    <DatePicker v-model="data.endDate" class="w-full flex-1 mr-2 max-w-auto rounded" id="end" :disabled-date="disabledBeforeStartDate" :open.sync="showDateEnd"></DatePicker>
                    <label @click="showDateEnd = true">
                    <img :src="imgCalendar" class="icoCalendar"  />
                    </label>
                    </div>
                    span.text-12.text-red(v-if="errorEndDate") Campo obligatorio
                  v-col.text-left.pb-0(cols="12")
                    span #[translate Añadir detalles]
                    input.inputFullEditModal.rounded(type="text" v-model="data.summary")
                  v-col.text-left.pb-0(cols="12")
                    span #[translate Código del cupón]
                    input.inputFullEditModal.rounded(type="text" v-model="data.couponCode")
                  v-col.text-left.pb-0(cols="12")
                    span #[translate Enlace para canjear la oferta]
                    input.inputFullEditModal.rounded(type="text" v-model="data.redeemOnlineUrl")
                  v-col.text-left.pb-0(cols="12")
                    span #[translate Términos y condiciones]
                    textarea.textareaFullEditModal.rounded(v-model="data.termsConditions")
                  v-col.text-left.pb-0(cols="12" v-if="isDemoFacebook && !data.createTime")
                    p.mb-0 Publicar tambien en:
                    v-row
                      v-col.d-flex.items-center(cols="12")
                        input.mr-1(type="checkbox" v-model="data.to_facebook")
                        img.mr-4(src="@/assets/img/local/FCB.svg" width="25")
                        input.mr-1(type="checkbox" v-model="data.to_instagram")
                        img(src="@/assets/img/local/INS.svg" width="25")

            v-col(cols="6")
              <addPhotos class="mt-7" @removeImage="remove()"  @loadImage="local_image = $event" :image="photo" :maxSize="5000000"></addPhotos>
              <span class="mt-8 d-block">Programar esta publicación:</span>
              <div class="d-flex">
              <DatePicker v-model="data.dateProgramation" class="w-full flex-1 mr-1 max-w-auto rounded" id="dateProgramation" :disabled-date="disabledAfterStartDate" :open.sync="showDateProgramation"></DatePicker>
              <label @click="showDateProgramation = true">
              <img :src="imgCalendar" class="icoCalendar"  />
              </label>
              <input type="time" class="inputFullEditModal rounded w-auto ml-3" v-model="data.timeProgramation" />
              </div>
          v-row#footerModalEdit
            v-col(cols="12")
              button.btnCancelEditModal(@click.stop="show=false") #[translate Cancel]
              button.btnSaveEditModal(v-if="idsBussinesModeMultiple" @click="showConfirm=true")
                span(v-if="isEditionMode") #[translate Guardar cambios]
                span(v-else) #[translate Publicar]
              button.btnSaveEditModal(v-else @click="validate()")
                span(v-if="isEditionMode") #[translate Guardar cambios]
                span(v-else) #[translate Publicar]
              button.btnDeleteEditModal(v-if="isEditionMode" @click="showDeleteModal=true")
                span #[translate Cancelar programación]

    modal-confirm-multiple-edit(:visible="showConfirm"  @close="showConfirm=false" @confirmed="validate()")
    modal-confirm-delete(:visible="showDeleteModal" @close="showDeleteModal=false" @confirmed="deleteEvent(data.id)")
</template>
<script>
import commonMixins from '@/mixins/common.js'
import SrvLocalPosts from '@/services/localPosts.js'
import DatePicker from 'vue2-datepicker';
import Errors from '@/components/common/Errors.vue'
import addPhotos from '@/components/local/posts/addPhotos.vue'
import ModalConfirmMultipleEdit from '@/components/local/confirmMultipleEdit.vue'
import ModalConfirmDelete from "@/components/local/confirmDelete.vue"
import SuccessState from "@/components/local/successState.vue"
import ErrorState from "@/components/local/errorState.vue"
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es'

export default {
  mixins: [commonMixins],
  props: ['visible', 'idBussines', 'post', 'idsBussinesModeMultiple', 'editionData'],
  components: {DatePicker, addPhotos, ModalConfirmMultipleEdit, Errors, SuccessState, ErrorState, ModalConfirmDelete},
  data() {
    return {
      loading: false,
      showConfirm: false,
      showDeleteModal: false,
      showSuccess: false,
      showError: false,
      imgClose: require('@/assets/img/ico-close-edit.svg'),
      imgCalendar: require('@/assets/img/local/calendar.svg'),
      name: '',
      showDateInit: false,
      showDateEnd: false,
      showDateProgramation: false,
      data: {
        topicType: 'OFFER', startDate: null, endDate: null, summary: null, local_image: null, eventName: null,
        startDay: null, startMonth: null, startYear: null,
        endDay: null, endMonth: null, endYear: null, couponCode: null,
        redeemOnlineUrl: null, termsConditions: null, to_facebook: false, to_instagram: false, dateProgramation: null, timeProgramation: null
      },
      local_image: null,
      section: 'details-restaurant',
      saveStatus: true,
      errors: null,
      errorEventName: false,
      errorStartDate: false,
      errorEndDate: false,
    }
  },
  watch: {
    editionData() {
      this.data = {...this.data, ...this.editionData}
      if (this?.editionData?.local_image) this.local_image = this.editionData.local_image
    },
    visible(newValue) {
      if (newValue) {
        this.showError = false
        this.showSuccess = false
        if (!this.isEditionMode) {
          if (this.post) {
            this.data.eventName = this.post.event.title
            this.data.startDate = this.$moment(this.post.event.schedule.startDate.year + '-' + this.post.event.schedule.startDate.month + '-' + this.post.event.schedule.startDate.day).toDate()
            this.data.endDate = this.$moment(this.post.event.schedule.endDate.year + '-' + this.post.event.schedule.endDate.month + '-' + this.post.event.schedule.endDate.day).toDate()
            this.data.startDay = parseInt(this.post.event.schedule.startDate.day)
            this.data.startMonth = parseInt(this.post.event.schedule.startDate.month)
            this.data.startYear = this.post.event.schedule.startDate.year
            this.data.endDay = parseInt(this.post.event.schedule.endDate.day)
            this.data.endMonth = parseInt(this.post.event.schedule.endDate.month)
            this.data.endYear = this.post.event.schedule.endDate.year
            this.data.name = this.post.name
            this.local_image = this.post.media.length > 0 ? this.post.media[0].googleUrl : null
            this.data.dateProgramation = null
            this.data.timeProgramation = null
          }
        }
      }
    },
    eventName(newVal) {
      if (newVal) {
        this.errorEventName = false
      }
    },
    startDate(newVal) {
      if (newVal) {
        this.errorStartDate = false
      }
    },
    endDate(newVal) {
      if (newVal) {
        this.errorEndDate = false
      }
    }

  },
  computed: {
    photo() {
      return this.local_image
    },
    isEditionMode() {
      return this.editionData
    },
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {

          this.$emit('close')
        }
      }
    },
    eventName() {
      return this.data.eventName
    },
    startDate() {
      return this.data.startDate
    },
    endDate() {
      return this.data.endDate
    }
  },
  methods: {
    emitSuccessEvent() {
      this.$emit("handleSuccess")
    },
    async deleteEvent(id) {
      try {
        this.loading = true;
        await SrvLocalPosts.deleteScheduledPost(id)
        this.emitSuccessEvent();
        this.show = false;
        this.showSuccess = true;
      } catch (error) {
        this.showError = true;
      } finally {
        this.loading = false;
      }
    },
    remove() {
      delete this.data.media
      this.local_image = null
    },
    validate() {
      let error = false
      if (!this.data.eventName) {
        this.errorEventName = true
        error = true
      } else {
        this.errorEventName = false
      }

      if (!this.data.startDate) {
        this.errorStartDate = true
        error = true
      } else {
        this.errorStartDate = false
      }

      if (!this.data.endDate) {
        this.errorEndDate = true
        error = true
      } else {
        this.errorEndDate = false
      }

      if (!error) {
        this.save()
      }
    },
    async save() {
      if (!this.loading) {
        this.loading = true
        const form = new FormData()
        form.append('topicType', 'OFFER')
        if (this.local_image && !this.data.media) {
          form.append('local_image', this.local_image)
        }
        if (this.data.startDate) {
          let partsStart = this.$moment(this.data.startDate).format('DD/MM/YYYY').split('/')
          form.append('startDay', parseInt(partsStart[0]))
          form.append('startMonth', parseInt(partsStart[1]))
          form.append('startYear', partsStart[2])
        }
        if (this.data.endDate) {
          let partsEnd = this.$moment(this.data.endDate).format('DD/MM/YYYY').split('/')
          form.append('endDay', parseInt(partsEnd[0]))
          form.append('endMonth', parseInt(partsEnd[1]))
          form.append('endYear', partsEnd[2])
        }
        if (this.data.summary) {
          form.append('summary', this.data.summary)
        }
        if (this.data.eventName) {
          form.append('eventName', this.data.eventName)
        }
        if (this.data.couponCode) {
          form.append('couponCode', this.data.couponCode)
        }
        if (this.data.redeemOnlineUrl) {
          form.append('redeemOnlineUrl', this.data.redeemOnlineUrl)
        }
        if (this.data.termsConditions) {
          form.append('termsConditions', this.data.termsConditions)
        }
        if (this.data.name) {
          form.append('name', this.data.name)
        }

        // Si es una creación simple de un post para un centro
        if (!this.idsBussinesModeMultiple && !this.data.dateProgramation && !this.data.timeProgramation) {
          const res = await SrvLocalPosts.setPost(this.idBussines, form, this.data.to_facebook, (this.local_image || this.data.media) && this.data.to_instagram ? true : false)
          if (res.status === 200) {
            this.$emit('saved', {data: res.data, 'action': 'update'})
            this.$emit('close')
          }
          if (res.status === 201) {
            this.$emit('saved', {data: res.data, 'action': 'create'})
            this.$emit('close')
          }
        }

        let valid = true
        if ((this.data.dateProgramation && !this.data.timeProgramation) || (!this.data.dateProgramation && this.data.timeProgramation)) {
          valid = false
          this.errors = 'Si desea programar esta publicación debe completar el campo de fecha y de hora'
        }

        // si vienen del listado para crear un post para varios centros
        if (this.idsBussinesModeMultiple || (this.data.dateProgramation && this.data.timeProgramation)) {
          let formMultiple = new FormData()
          let objClean = {}
          Object.entries(this.data).forEach((e) => {
            if (e[1] && e[0] !== 'startDate' && e[0] !== 'endDate') {
              objClean[e[0]] = e[1]
            }
            if (e[0] == 'startDate') {
              let partsStart = this.$moment(this.data.startDate).format('DD/MM/YYYY').split('/')
              objClean['startDay'] = parseInt(partsStart[0])
              objClean['startMonth'] = parseInt(partsStart[1])
              objClean['startYear'] = partsStart[2]
            }
            if (e[0] == 'endDate') {
              let partsEnd = this.$moment(this.data.endDate).format('DD/MM/YYYY').split('/')
              objClean['endDay'] = parseInt(partsEnd[0])
              objClean['endMonth'] = parseInt(partsEnd[1])
              objClean['endYear'] = partsEnd[2]
            }
          })
          delete objClean.local_image
          delete objClean.timeProgramation
          delete objClean.dateProgramation
          let centers = this.idsBussinesModeMultiple
          if (!this.idsBussinesModeMultiple && this.data.dateProgramation && this.data.timeProgramation) {
            centers = [this.idBussines]
          }

          if (this.data.dateProgramation && this.data.timeProgramation) {
            const [hours, minutes] = this.data.timeProgramation.split(':')
            formMultiple.append('scheduled_datetime', this.$moment(this.data.dateProgramation).set({hour: hours, minute: minutes}).utc().format("YYYY-MM-DD-HH:mm:ss"))
          }

          formMultiple.append('post_details', JSON.stringify({"center_ids": centers, "update_type": "POST", "post_details": objClean}))
          if (this.local_image) {
            formMultiple.append('image', this.local_image);
          }
          formMultiple.append('update_type', 'POST');

          if (valid) {
            try {
              const res = this.isEditionMode ? await SrvLocalPosts.updateScheduledPost(formMultiple, this.data.id) : await SrvLocalPosts.setPostMultiple(formMultiple)
              if (res.status === 200) {
                this.show = false
                this.showSuccess = true
                this.emitSuccessEvent();
              }
            } catch (error) {
              this.showError = true;
            }
          }

        }
        this.loading = false
      }

    }
  }
}
</script>
<style lang="scss" scoped>
.boxDeliveryService {
  background-color: #F2F2F2;
  border-radius: 4px;
  width: 100%;
  margin: 6px;

  &.noService {
    .urlDeliveryService {
      color: #CECECE;
    }
  }

  img {
    vertical-align: middle;
  }
}

.long {
  height: 136px;
}

.messageError {
  background-color: #F5B1B8;
  border: 1px solid #F93549;
  color: #F93549;
  padding: 15px;
}

#bodyModalEdit {
  .inputFullEditModal {
    &.textDisabled {
      background-color: #f8f8f8;
      color: #CECECE;
    }
  }
}

.icoCalendar {
  height: 36px;
}
</style>
