<template lang="pug">
  v-menu(v-model="menu" :content-class="classFixedMenuVuetify" :close-on-content-click="false" :nudge-width="100" :disabled="items.length === 0 || (items.length === 1 && firstIsDefault)" offset-y)
    template(v-slot:activator="{ on, attrs }")
      v-badge.gr-filter-menu-button(:style="{backgroundColor: bgColor}" bordered :content="Array.isArray(tree) && tree.length > 1 ? `+${tree.length - 1}` : ''" :value="Array.isArray(tree) && tree.length > 1" overlap)
        v-btn.text-caption.justify-start.pr-2(:min-width="minWidth" :style="{ backgroundColor: items.length === 0 || (items.length === 1 && firstIsDefault) ? '#e2e2e2' : 'transparent' }" outlined text v-bind="attrs" v-on="on")
          span.flex-fill.text-left(v-if="items.length === 0 || (items.length === 1 && firstIsDefault)")
            | {{ $gettext('N/A') }}
          span.primary--text.flex-fill.text-left(v-else-if="type === 'radio' && typeof selected !== 'undefined'")
            | {{ value || selected }}
          span.primary--text.flex-fill.text-left(v-else-if="type === 'radio' && items.length && typeof selected !== 'undefined'")
            | {{ firstElement[radioValueKey] }}
          span.primary--text.flex-fill.text-left(v-else-if="type === 'unique' && items.length && tree !== undefined")
            | {{ firstElement && firstElement[labelKey] }}
          span.primary--text.flex-fill.text-left(v-else-if="type === 'checkbox' && items.length && tree.length")
            | {{ firstElement[labelKey] }}
          span.primary--text.flex-fill.text-left(v-else)
            | {{ $gettext('All') }}
          v-icon.gr-filter-icon(small color="#4EA5F5" :class="{ 'gr-filter-icon-open' : menu }")
            | mdi-chevron-down
    v-card.gr-filter(:class="{ 'gr-filter__level_no_margin': nodeZeroMargin }")
      v-sheet.pa-4.primary.lighten-2(v-if="searchable || optionSelectAll")
        v-text-field(v-if="searchable" v-model="search" label="Search" dark flat solo-inverted hide-details clearable clear-icon="mdi-close-circle-outline")
        v-checkbox(v-if="optionSelectAll" :class="{ 'mt-0': !searchable }" v-model="selectAll" dark hide-details :label="labelSelectAll")
      v-divider
      v-card.overflow-y-auto.gr-filter-tree-menu_treeview(flat)
        v-card-text(@mouseup="checkAll")
          v-list(v-if="type === 'unique'")
            v-list-item-group(v-model="tree" )
              template(v-for="(item, i) in items")
                v-list-item(:key="`item-${i}`" :value="item.value" shaped flat dense hoverable light :ripple="false")
                  template(v-slot:default="{ active }")
                    v-list-item-action
                      v-checkbox(:input-value="active")
                    v-list-item-content
                      v-list-item-title(v-text="item.text")
          v-treeview(v-else-if="type === 'checkbox'" v-model="tree" :item-key="idKey" :item-text="labelKey" :search="search" selection-type="all" selectable dense hoverable light open-all rounded :items="items")
            template(slot="label" slot-scope="{ item }")
              a(:class="labelClass" @mousedown="itemClick($event, item)" :data-id="item[idKey]") {{ enableLabelTranslation ? $gettext(item[labelKey]) : item[labelKey] }}
          v-radio-group.radio-tree(v-else-if="type === 'radio'" v-model="value" :item-key="idKey" :item-text="labelKey" :search="search" selection-type="leaf" selectable dense hoverable light open-all rounded :items="items")
            v-treeview(:item-key="idKey" :item-text="labelKey" shaped :search="search" selection-type="all" dense hoverable light open-all rounded :items="items")
              template(slot="label" slot-scope="{ item }")
                v-radio(:label="item[labelKey]" :value="item[labelKey]")
                  template(slot="label")
                    a(:class="labelClass") {{ item[labelKey] }}
      v-divider
      v-card-actions.justify-center
        v-btn.px-8(color="primary" elevation="1" tile small @mousedown="apply")
          | #[translate Apply]
</template>

<script>
import commonMixins from "@/mixins/common.js";

export default {
  name: "filterTreeMenu",
  mixins: [commonMixins],
  props: {
    'classFixedMenuVuetify': {
      default: '',
      type: String,
      required: false
    },
    'enableLabelTranslation': {
      default: true,
      type: Boolean,
      required: false
    },
    'bgColor': {
      type: String,
      default: '#fff'
    },
    'options': Array,
    'itemsChecked': Array,
    'selected': undefined,
    'idKey': {
      type: String,
      default: 'id'
    },
    'labelKey': {
      type: String,
      default: 'label'
    },
    'idOutputKey': {
      type: String,
      default: 'name'
    },
    'labelOutputKey': {
      type: String,
      default: 'value'
    },
    'searchable': {
      type: Boolean,
      default: false
    },
    'optionSelectAll': {
      type: Boolean,
      default: true
    },
    'type': {
      type: String,
      default: 'checkbox'
    },
    'radioValueKey': {
      type: String,
      default: undefined
    },
    'minWidth': {
      type: String,
      default: 'auto'
    },
    'firstIsDefault': {
      type: Boolean,
      default: false
    },
    'labelClass': {
      type: String,
      default: ''
    },
  },
  data: () => ({
    menu: false,
    search: null,
    selectAll: true,
    left: false,
    tree: [],
    value: undefined,
    nodeZeroMargin: true,
    lastTree: []
  }),
  mounted() {
    this.$nextTick(() => {
      if (this.type === 'checkbox') {
        this.tree = this.itemsChecked
        this.checkAll()
      } else if (this.type === 'unique') {
        this.tree = this.itemsChecked[0]
        this.checkAll()
      } else if (this.type === 'radio') {
        this.value = this.selected
      }
    })
  },
  watch: {
    // PARA ACTUALIZAR FILTRO CUANDO PULSAN EN LAS CATEGORIAS DE CADA OPINION
    itemsChecked(val) {
      if (this.type === 'checkbox') {
        this.tree = val
        this.checkAll()
      } else if (this.type === 'unique') {
        if (Array.isArray(val)) {
          if (val.length) {
            this.tree = val[0][this.idKey]
          }
        } else {
          this.tree = val
        }
        this.checkAll()
      } else if (this.type === 'radio') {
        this.value = this.selected
      }
    },
    selectAll(value) {
      if (this.type === "unique") {
        if (value) {
          this.lastTree = this.tree;
          this.tree = undefined;
        } else {
          this.tree = this.lastTree;
        }
      } else {
        if (value) {
          this.lastTree = this.tree;
          this.tree = [];
        } else {
          this.tree = this.lastTree;
        }
      }
    },
    menu: {
      immediate: true,
      handler() {
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 300)
      },
    },
    selected: {
      immediate: true,
      handler(value) {
        this.$nextTick(() => {
          this.value = value
        })
      },
    },
    value: {
      immediate: true,
      handler(value) {
        this.$nextTick(() => {
          if (!value) {
            this.value = this.selected
          }
        })
      },
    },
    options: {
      immediate: true,
      handler(items) {
        this.nodeZeroMargin = !items.some(item => item.children && item.children.length)
      },
    }
  },
  methods: {
    checkAll() {
      setTimeout(() => {
        if (this.type === "unique") {
          const current = this.tree
          const allIds = [...this.allIds]
          if (allIds.some(item => this.tree === item)) {
            this.selectAll = false
            this.$nextTick(() => {
              this.tree = current
            })
          }
        } else {
          const current = [...this.tree]
          const allIds = [...this.allIds]
          if (allIds.some(item => current.includes(item))) {
            this.selectAll = false
            this.$nextTick(() => {
              this.tree = current
            })
          }
        }
      }, 0)
    },
    itemClick(event, item) {
      this.$nextTick(() => {
        const index = this.tree.indexOf(item[this.idKey]);
        if (index > -1) {
          this.tree.splice(index, 1);
        } else {
          this.tree.push(item[this.idKey])
        }
      })
    },
    apply() {
      if (this.type === 'checkbox') {
        const selected = this.tree.map(item => {
          const element = this.getElementById(item)
          return {[this.idOutputKey]: element[this.labelKey], [this.labelOutputKey]: item, type: element.type}
        })
        this.$emit('checked', selected)
      } else if (this.type === 'unique') {
        const item = this.items.filter(item => item.value === this.tree)
        this.$emit('checked', item.length ? item : undefined)
      } else if (this.type === 'radio') {
        this.$emit('checked', this.items.filter(item => item[this.radioValueKey] === this.value)[0])
      }
      this.menu = false;
    },
    getElementById(id) {
      return this.findItemNested(this.items, id, 'children', this.idKey)
    }
  },
  computed: {
    labelSelectAll() {
      return this.$gettext('Select all')
    },
    allIds() {
      return this.items.map((item) => item[this.idKey]);
    },
    items() {
      return this.options
    },
    firstElement() {
      if (this.type === 'checkbox') {
        return this.findItemNested(this.items, this.tree[0], 'children', this.idKey)
      } else if (this.type === 'unique') {
        return this.items.find(item => item.value === this.tree)
      } else if (this.type === 'radio') {
        const first = this.items.filter(item => item[this.radioValueKey] === this.value)
        return first[0] ? first[0] : undefined
      } else {
        return []
      }
    }
  }
}
</script>

<style lang="scss">
.v-btn {
  text-transform: none;
}

.gr-filter {

  .v-label,
  .v-treeview-node__label a {
    font-size: 12px;
  }

  .v-messages {
    height: 0;
    min-height: 0;
  }
}

.v-list-item::before {
  border-radius: 24px !important;

  .v-ripple__container {
    background-color: transparent !important;
  }
}

.gr-filter__level_no_margin .v-treeview-node__level {
  width: 0;
}

.v-list--dense .v-list-item,
.v-list-item--dense {
  min-height: 35px !important;
}

.v-list--dense .v-list-item .v-list-item__content,
.v-list-item--dense .v-list-item__content {
  padding: 0;
}

.v-list-item__title {
  font-size: 12px !important;
}

.v-list {
  padding: 0 !important;
}

.v-list-item__action {
  margin: 0;
}

.gr-filter-tree-menu_treeview {

  max-height: calc(50vh);
  height: auto;

  .v-treeview-node__label {
    flex: initial;

    a {
      user-select: none;
    }
  }

  .v-treeview-node.v-treeview-node--rounded .v-treeview-node__root {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.gr-filter-icon {
  transition: all 0.75s 0.25s;
  transform: rotate(0);
}

.gr-filter-icon-open {
  transform: rotate(-180deg);
}

.gr-filter-menu-button {

  .v-btn__content {
    font-family: "Open Sans", sans-serif;
  }

  .v-btn:before {
    background-color: deepskyblue;
  }

  .v-ripple__container {
    background-color: rgba(181, 202, 212, 0);
  }

  .v-ripple__animation {
    background: rgba(0, 191, 255, 0.5);
  }
}

.radio-tree {
  margin: 0;
}

.radio-tree.v-input--radio-group > .v-input__control {
  width: 100%;
}

.radio-tree.v-input--radio-group > .v-input__control > .v-input__slot {
  margin: 0;
}

.radio-tree.v-input--radio-group .v-treeview-node__label {
  overflow: visible;
}</style>
