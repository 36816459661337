<template lang="pug">
  div
    v-col(md="12").contentOutPadding.tablaGR
      v-data-table(
        :headers="headers"
        :items="configs"
        hide-default-footer
        class="elevation-1"
        item-key="idGenerate"
        :loading="loading"
      )
        template(v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }")
          span.d-flex(v-if="header.value === 'actions'")
            span
          span(v-else) {{ header.text }}

        template(v-slot:item.config_name="{ item }")
          span.d-flex.items-center
            img(:src="img.config")
            span.pl-3.text-13 {{ item.name }}
        template(v-slot:item.brand="{ item }")
          span(v-if="item.brand").d-flex.items-center.text-13 {{ item.brand }}
          span(v-else).d-flex.items-center.text-13 -
        template(v-slot:item.restaurant="{ item }")
          span(v-if="item.node").d-flex.items-center.text-13 {{ item.node.name }}
          span(v-else-if="item.center").d-flex.items-center.text-13 {{ item.center.name }}
          span(v-else).d-flex.items-center.text-13 -
        template(v-slot:item.category="{ item }")
          span(v-if="item.category").d-flex.items-center.text-13 {{ selectedCategory(item.category)}}
          span(v-else).d-flex.items-center.text-13 -
        template(v-slot:item.stars="{ item }")
          stars-combination(:combination="item.ratings")
        template(v-slot:item.text="{ item }")
          span.d-flex.items-center.text-13
            span(v-if="item.has_text") #[translate SI]
            span(v-else) #[translate NO]
        template(v-slot:item.automatic_answer="{ item }")
          div.d-flex.justify-center
            switch-sync(:sync="item.is_automatic" status="" :is-extended="false" :pos="0" :id="`switch_${item.id}`" @changeSync="(value)=>handleIsAutoChange(value,item)")
        template(v-slot:item.actions="{ item }")
          div.d-flex.justify-center
            button.hover-resize.action-button.px-2.py-4(@click="handleEdit(item)")
              img(:src="img.edit" style="width: 20px; height: 20px")
            button.hover-resize.action-button.px-2.py-4(@click="handleDelete(item.id)")
              img(:src="img.trash" style="width: 20px; height: 18px")
</template>

<script>
import StarsCombination from "@/components/feedback/StarsCombinations.vue"
import SwitchSync from "@/components/local/SwitchSync.vue"
import common from "@/mixins/common";
import FeedBackService from "@/services/feedback"

export default {
  mixins: [common],
  props: {search: {type: String, default: ""}, configs: {default: []}, loading: {type: Boolean, default: false}},
  components: {StarsCombination, SwitchSync},
  data() {
    return {
      img: {
        config: require('@/assets/img/config.svg'),
        trash: require('@/assets/img/trash.svg'),
        edit: require('@/assets/img/edit.svg'),
      },
      currentLevelLabel: 'center',
      translatedLevels: {
        zone: this.$gettext('Zona'),
        brand: this.$gettext('Marca'),
        subzone: this.$gettext('Región'),
        local: this.$gettext('Centro'),
        center: this.$gettext('Centro'),
      },
      currentItem: null
    }
  },
  mounted() {
    for (const level in this.level) {
      if (this.level[level]) {
        this.currentLevelLabel = level
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$gettext("NOMBRE CONFIGURACIÓN"),
          align: "start",
          sortable: false,
          value: "config_name",
        },
        {text: this.translatedLevels[this.currentLevelLabel].toUpperCase(), value: "brand", align: "start", sortable: false},
        {text: this.$gettext("RESTAURANTE"), value: "restaurant", align: "start", sortable: false},
        {text: this.$gettext("ESTRELLAS"), value: "stars", align: "start", sortable: false, width: "10%"},
        {text: this.$gettext("TEXTO"), value: "text", sortable: false},
        {text: this.$gettext("CATEGORÍA"), value: "category", align: "start", sortable: false},
        {text: this.$gettext("RESPUESTA AUTOMÁTICA"), value: "automatic_answer", sortable: false, width: "10%"},
        {text: this.$gettext("ACCIONES"), value: "actions", sortable: false, width: "10%"},
      ];
    },
  },
  methods: {
    handleEdit(item) {
      this.$emit('onEdit', item)
    },
    handleDelete(id) {
      this.$emit('onDelete', id)
    },
    async handleIsAutoChange(value, item) {
      try {
        await FeedBackService.updateAutomaticAnswer(item.id, {is_automatic: value.state})
      } catch (error) {
        console.log("ERROR_handleIsAutoChange", error)
      }
    },
    selectedCategory(category) {
      const categories = this.$store.getters.getCategories?.internet?.categories ?? []
      const item = categories.filter(_item => _item.id === +category) ?? []

      if (!item.length) return "-"
      return item?.[0].name
    }
  }
}
</script>

<style scoped>
.action-button {
  background-color: #EBEBEB;
  border-radius: 2px;
  max-width: fit-content;
}
</style>
